<template>
    <main class="main">
        <div class="container-fluid px-0" data-layout="container">

            <aside-main/>

            <header-main/>

            <div class="content">
                <form class="mb-9" v-on:submit.prevent="createItem">
                    <div class="row justify-content-between align-items-end g-3 mb-5">
                        <div class="col-12 col-sm-auto col-xl-8">
                            <h2>Создать Промокод</h2>
                        </div>
                        <div class="col-12 col-sm-auto col-xl-4">
                            <div class="d-flex">
                                <button
                                    :class="{'disabled': btn_preloader}"
                                    class="btn btn-primary px-5 w-100 text-nowrap" 
                                    type="submit">
                                    <span class="spinner-border spinner-border-sm spinner-border-custom" role="status" v-if="btn_preloader"></span>
                                    <span>Создать</span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="row g-5">
                        <div class="col-xl-12">

                            <div class="card-header p-2 d-flex flex-between-center">
                                <div class="d-flex align-items-center">
                                    <h4 class="fs-1 mb-2">Основная информация</h4>
                                </div>

                                <div class="d-flex">
                                    <input 
                                        v-model="data.is_partner"
                                        class="form-check-input" 
                                        id="is_partner" 
                                        type="checkbox">
                                    <label class="form-check-label" for="is_partner" style="margin-left: 5px; margin-top: 1.5px;">Партнерский</label>
                                </div>
                            </div>

                            <div class="row g-3">
                                <div class="col-sm-6 col-md-12">
                                    <div class="form-floating">
                                        <input
                                            v-model="data.name"
                                            @input="errors.name = null"
                                            :class="{'is-invalid': errors.name}"
                                            placeholder="Название"
                                            class="form-control" 
                                            type="text"/>
                                        <label>Название</label>
                                        <div class="invalid-feedback">{{errors.name}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <input
                                            v-model="data.promocode"
                                            @input="errors.promocode = null, promocodeUpperCase()"
                                            :class="{'is-invalid': errors.promocode}"
                                            placeholder="Промокод"
                                            class="form-control" 
                                            type="text"/>
                                        <label>Промокод</label>
                                        <div class="invalid-feedback">{{errors.promocode}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <input
                                            v-model="data.count_activate"
                                            @input="errors.count_activate = null"
                                            :class="{'is-invalid': errors.count_activate}"
                                            placeholder="Количество активаций"
                                            class="form-control" 
                                            type="text"/>
                                        <label>Количество активаций</label>
                                        <div class="invalid-feedback">{{errors.count_activate}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating v-select-medium" :class="{'v-select-error': errors.type}">
                                        <vue-select
                                            @input="errors.type = null, changeType()"
                                            v-model="data.type"
                                            :options="utils.types" 
                                            placeholder="Тип"
                                            label="name">
                                            <template v-slot:no-options="{ search, searching }">
                                              <template v-if="searching">
                                                 Ничего не найдено по запросу <em>{{ search }}</em>.
                                              </template>
                                            </template>
                                        </vue-select>
                                        <label>Тип</label>
                                        <div class="invalid-feedback">{{errors.type}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating v-select-medium" :class="{'v-select-error': errors.action}">
                                        <vue-select
                                            @input="errors.action = null, changeAction()"
                                            v-model="data.action"
                                            :options="utils.actions" 
                                            placeholder="Действие"
                                            label="name">
                                            <template v-slot:no-options="{ search, searching }">
                                              <template v-if="searching">
                                                 Ничего не найдено по запросу <em>{{ search }}</em>.
                                              </template>
                                            </template>
                                        </vue-select>
                                        <label>Действие</label>
                                        <div class="invalid-feedback">{{errors.action}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating v-select-medium" :class="{'v-select-error': errors.method}">
                                        <vue-select
                                            @input="errors.method = null"
                                            v-model="data.method"
                                            :options="utils.methods" 
                                            placeholder="Method"
                                            label="name">
                                            <template v-slot:no-options="{ search, searching }">
                                              <template v-if="searching">
                                                 Ничего не найдено по запросу <em>{{ search }}</em>.
                                              </template>
                                            </template>
                                        </vue-select>
                                        <label>Method</label>
                                        <div class="invalid-feedback">{{errors.method}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <input
                                            v-model="data.wager"
                                            @input="errors.wager = null"
                                            :class="{'is-invalid': errors.wager}"
                                            placeholder="Wager"
                                            class="form-control" 
                                            type="text"/>
                                        <label>Wager</label>
                                        <div class="invalid-feedback">{{errors.wager}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <date-range-picker
                                            :class="{'is-invalid': errors.expire}"
                                            @update="errors.expire = null"
                                            :locale-data="datepiker"
                                            :opens="pikerparams.opens"
                                            :close-on-esc="pikerparams.closeonesc"
                                            :auto-apply="pikerparams.autoapply"
                                            :single-date-picker="pikerparams.single"
                                            :minDate="pikerparams.mindate"
                                            :ranges="pikerparams.ranges"
                                            :showDropdowns="pikerparams.dropdowns"
                                            :time-picker="pikerparams.timePicker"
                                            :time-picker24-hour="pikerparams.timePicker24Hour"
                                            v-model="data.expire">
                                        </date-range-picker>

                                        <label>Истекает</label>
                                        <div class="invalid-feedback">{{errors.expire}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <input
                                            v-model="data.expire_in_days"
                                            @input="errors.expire_in_days = null"
                                            :class="{'is-invalid': errors.expire_in_days}"
                                            placeholder="Истекает через N Дней"
                                            class="form-control" 
                                            type="text"/>
                                        <label>Истекает через N Дней</label>
                                        <div class="invalid-feedback">{{errors.expire_in_days}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating v-select-medium" :class="{'v-select-error': errors.platforms}">
                                        <vue-select
                                            multiple
                                            @input="errors.platforms = null"
                                            v-model="data.platforms"
                                            :options="utils.platforms" 
                                            placeholder="Платформы"
                                            label="name">
                                            <template v-slot:no-options="{ search, searching }">
                                              <template v-if="searching">
                                                 Ничего не найдено по запросу <em>{{ search }}</em>.
                                              </template>
                                            </template>
                                        </vue-select>
                                        <label>Платформы</label>
                                        <div class="invalid-feedback">{{errors.platforms}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating v-select-medium" :class="{'v-select-error': errors.status}">
                                        <vue-select
                                            @input="errors.status = null"
                                            v-model="data.status"
                                            :options="utils.statuses" 
                                            placeholder="Статус"
                                            label="name">
                                            <template v-slot:no-options="{ search, searching }">
                                              <template v-if="searching">
                                                 Ничего не найдено по запросу <em>{{ search }}</em>.
                                              </template>
                                            </template>
                                        </vue-select>
                                        <label>Статус</label>
                                        <div class="invalid-feedback">{{errors.status}}</div>
                                    </div>
                                </div>


                                <h4 class="fs-1 mt-5">Настройка промокода</h4>

                                <template v-if="data.type.key == 1 && refresh_merchant_data">

                                    <template v-for="(item, key) in utils.merchants">
                                        <div class="col-sm-6 col-md-4" :class="{'col-md-6': data.action.key == 2}">
                                            <div class="form-floating v-select-medium">
                                                <vue-select
                                                    disabled
                                                    v-model="item.name"
                                                    label="name">
                                                    <template v-slot:no-options="{ search, searching }">
                                                      <template v-if="searching">
                                                         Ничего не найдено по запросу <em>{{ search }}</em>.
                                                      </template>
                                                    </template>
                                                </vue-select>
                                                <label>Мерчант</label>
                                            </div>
                                        </div>

                                        <div class="col-sm-6 col-md-4" v-if="data.action.key != 2">
                                            <div class="form-floating">
                                                <input
                                                    v-model="data.merchants[item.id].min_deposit"
                                                    @input="errors.merchants[item.id].min_deposit = null, refreshMerchantData()"
                                                    :class="{'is-invalid': errors.merchants[item.id].min_deposit}"
                                                    placeholder="Мин. сумма депозита"
                                                    class="form-control" 
                                                    type="text"/>
                                                <label>Мин. сумма депозита</label>
                                                <div class="invalid-feedback">{{ errors.merchants[item.id].min_deposit }}</div>
                                            </div>
                                        </div>
                                        
                                        <div class="col-sm-6 col-md-4" :class="{'col-md-6': data.action.key == 2}">
                                            <div class="form-floating">
                                                <input
                                                    v-model="data.merchants[item.id].amount"
                                                    @input="errors.merchants[item.id].amount = null, refreshMerchantData()"
                                                    :class="{'is-invalid': errors.merchants[item.id].amount}"
                                                    placeholder="Сумма промокода"
                                                    class="form-control" 
                                                    type="text"/>
                                                <label>Сумма промокода</label>
                                                <div class="invalid-feedback">{{ errors.merchants[item.id].amount }}</div>
                                            </div>
                                        </div>
                                    </template>
                                </template>

                                <template v-else-if="data.type.key == 2 && refresh_merchant_data">

                                    <div class="col-sm-6 col-md-6">
                                        <div class="form-floating v-select-medium" :class="{'v-select-error': errors.game}">
                                            <vue-select
                                                @input="errors.game = null"
                                                @search="searchGames"
                                                v-model="data.game"
                                                :options="utils.games" 
                                                placeholder="Игра"
                                                label="name">
                                                <template v-slot:option="option">
                                                    <img style="width: 50px;margin-right: 20px;height: 100%; height: 35px;" :src="option.image"/>
                                                    <span>{{ option.name }}</span>
                                                    <template v-if="option.is_mobile">
                                                        <span class="badge badge-casino fs--3 badge-casino-warning" style="float: right;margin-top: 10px;margin-left: 10px;">Mobile</span>
                                                    </template>
                                                    <template v-if="option.is_desktop">
                                                        <span class="badge badge-casino fs--3 badge-casino-success" style="float: right;margin-top: 10px;margin-left: 10px;">Desktop</span>
                                                    </template>
                                                </template>
                                                <template v-slot:no-options="{ search, searching }">
                                                    <template v-if="searching && search.length >= 3">
                                                        Ничего не найдено по запросу <em>{{ search }}</em>.
                                                    </template>
                                                    <span v-else>Введите не менее 3 символов</span>
                                                </template>
                                            </vue-select>
                                            <label>Игра</label>
                                            <div class="invalid-feedback">{{errors.game}}</div>
                                        </div>
                                    </div>

                                    <div class="col-sm-6 col-md-6">
                                        <div class="form-floating">
                                            <input
                                                v-model="data.freespins_number"
                                                @input="errors.freespins_number = null"
                                                :class="{'is-invalid': errors.freespins_number}"
                                                placeholder="Количество фриспинов"
                                                class="form-control" 
                                                type="text"/>
                                            <label>Количество фриспинов</label>
                                            <div class="invalid-feedback">{{errors.freespins_number}}</div>
                                        </div>
                                    </div>

                                    <template v-for="(item, key) in utils.merchants" v-if="data.game && data.freespins_number > 0 && refresh_merchant_data">
                                        <div class="col-sm-6 col-md-4" :class="{'col-md-6': data.action.key == 2}">
                                            <div class="form-floating v-select-medium">
                                                <vue-select
                                                    disabled
                                                    v-model="item.name"
                                                    label="name">
                                                    <template v-slot:no-options="{ search, searching }">
                                                      <template v-if="searching">
                                                         Ничего не найдено по запросу <em>{{ search }}</em>.
                                                      </template>
                                                    </template>
                                                </vue-select>
                                                <label>Мерчант</label>
                                            </div>
                                        </div>

                                        <div class="col-sm-6 col-md-4" v-if="data.action.key != 2">
                                            <div class="form-floating">
                                                <input
                                                    v-model="data.merchants[item.id].min_deposit"
                                                    @input="errors.merchants[item.id].min_deposit = null, refreshMerchantData()"
                                                    :class="{'is-invalid': errors.merchants[item.id].min_deposit}"
                                                    placeholder="Мин. сумма депозита"
                                                    class="form-control" 
                                                    type="text"/>
                                                <label>Мин. сумма депозита</label>
                                                <div class="invalid-feedback">{{ errors.merchants[item.id].min_deposit }}</div>
                                            </div>
                                        </div>

                                        <div class="col-sm-6 col-md-4" :class="{'col-md-6': data.action.key == 2}">
                                            <div class="form-floating v-select-medium" :class="{'v-select-error': errors.merchants[item.id].bet}">
                                                <vue-select
                                                    @open="openBetMerchant(item)"
                                                    @input="errors.merchants[item.id].bet = null, refreshMerchantData()"
                                                    v-model="data.merchants[item.id].bet"
                                                    :options="utils.freespins_bets[item.id]" 
                                                    placeholder="Сумма бета"
                                                    label="total_bet_per_line">
                                                    <template v-slot:option="option">
                                                        <span>{{ option.total_bet_per_line }}</span>
                                                    </template>
                                                    <template v-slot:no-options="{ search, searching }">
                                                      <template v-if="searching">
                                                         Ничего не найдено по запросу <em>{{ search }}</em>.
                                                      </template>
                                                      <template v-else>
                                                         Идет поиск бетов под мерчант <em>{{item.name}}</em>.
                                                      </template>
                                                    </template>
                                                </vue-select>
                                                <label>Сумма бета</label>
                                                <div class="invalid-feedback">{{ errors.merchants[item.id].bet }}</div>
                                            </div>
                                        </div>
                                    </template>
                                </template>

                                <template v-if="data.type.key == 3 && refresh_merchant_data">

                                    <div class="col-sm-6 col-md-12">
                                        <div class="form-floating">
                                            <input
                                                v-model="data.percent"
                                                @input="errors.percent = null"
                                                :class="{'is-invalid': errors.percent}"
                                                placeholder="Процент"
                                                class="form-control" 
                                                type="text"/>
                                            <label>Процент</label>
                                            <div class="invalid-feedback">{{errors.percent}}</div>
                                        </div>
                                    </div>

                                    <template v-for="(item, key) in utils.merchants">
                                        <div class="col-sm-6 col-md-4">
                                            <div class="form-floating v-select-medium">
                                                <vue-select
                                                    disabled
                                                    v-model="item.name"
                                                    label="name">
                                                    <template v-slot:no-options="{ search, searching }">
                                                      <template v-if="searching">
                                                         Ничего не найдено по запросу <em>{{ search }}</em>.
                                                      </template>
                                                    </template>
                                                </vue-select>
                                                <label>Мерчант</label>
                                            </div>
                                        </div>

                                        <div class="col-sm-6 col-md-4">
                                            <div class="form-floating">
                                                <input
                                                    v-model="data.merchants[item.id].min_deposit"
                                                    @input="errors.merchants[item.id].min_deposit = null, refreshMerchantData()"
                                                    :class="{'is-invalid': errors.merchants[item.id].min_deposit}"
                                                    placeholder="Мин. сумма депозита"
                                                    class="form-control" 
                                                    type="text"/>
                                                <label>Мин. сумма депозита</label>
                                                <div class="invalid-feedback">{{ errors.merchants[item.id].min_deposit }}</div>
                                            </div>
                                        </div>
                                        
                                        <div class="col-sm-6 col-md-4">
                                            <div class="form-floating">
                                                <input
                                                    v-model="data.merchants[item.id].max_deposit"
                                                    @input="errors.merchants[item.id].max_deposit = null, refreshMerchantData()"
                                                    :class="{'is-invalid': errors.merchants[item.id].max_deposit}"
                                                    placeholder="Макс. сумма депозита"
                                                    class="form-control" 
                                                    type="text"/>
                                                <label>Макс. сумма депозита</label>
                                                <div class="invalid-feedback">{{ errors.merchants[item.id].max_deposit }}</div>
                                            </div>
                                        </div>
                                    </template>
                                </template>

                            </div>
                        </div>
                    </div>
                </form>
                
                <footer-main/>

            </div>

        </div>
    </main>
</template>

<script>
export default {
    data () {
        return {
            btn_preloader: false,
            refresh_merchant_data: null,
            data: {
                is_partner: false,
                name: null,
                promocode: null,
                count_activate: null,
                wager: null,
                type: {
                    name: 'Ваучер',
                    key: 1
                },
                action: {
                    name: 'За депозит',
                    key: 1
                },
                method: {
                    name: 'Бонусный баланс',
                    key: 0
                },
                expire: {
                    startDate: this.$moment().set({day: this.$moment().get('day') + 30}).format('Y-MM-DD HH:mm:ss')
                },
                expire_in_days: 7,
                platforms: [],
                status: {
                    name: 'Отключен',
                    key: 0
                },
                merchants: {},
                game: null,
                freespins_number: null,
                percent: null
            },
            errors: {
                name: false,
                promocode: false,
                count_activate: false,
                wager: false,
                type: false,
                action: false,
                method: false,
                expire: false,
                expire_in_days: false,
                platforms: false,
                status: false,
                merchants: {},
                game: false,
                freespins_number: false,
                percent: false
            },
            utils: {
                merchants: [],
                games: [],
                freespins_bets: [],
                types: [
                    {
                        name: 'Ваучер',
                        key: 1
                    },
                    /*{
                        name: 'Бесплатные вращения',
                        key: 2
                    },*/
                    {
                        name: 'Кэш Бонус',
                        key: 3
                    }
                ],
                statuses: [
                    {
                        name: 'Включен',
                        key: 1
                    },
                    {
                        name: 'Отключен',
                        key: 0
                    }
                ],
                actions: [
                    {
                        name: 'За депозит',
                        key: 1
                    },
                    {
                        name: 'Бездепозитный',
                        key: 2
                    }
                ],
                platforms: [
                    {
                        name: 'Prill',
                        key: 0
                    },
                    {
                        name: 'Web',
                        key: 1
                    }
                ],
                methods: [
                    {
                        name: 'Бонусный баланс',
                        key: 0
                    },
                    {
                        name: 'Реальный баланс',
                        key: 1
                    }
                ]
            },
            datepiker: {
                direction: 'ltr',
                format: 'yyyy.mm.dd HH:MM',
                separator: ' - ',
                applyLabel: 'Применить',
                cancelLabel: 'Отмена',
                weekLabel: 'W',
                customRangeLabel: 'Настраиваемый диапазон',
                daysOfWeek: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
                monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
                firstDay: 0
            },
            pikerparams: {
                showcalendars: false,
                closeonesc: true,
                autoapply: true,
                single: true,
                ranges: false,
                dropdowns: true,
                mindate: this.$moment().format('Y-MM-DD HH:mm:ss'),
                timePicker: true,
                timePicker24Hour: true,
                opens: 'right'
            }
        }
    },
    methods: {
        createItem () {
            if(!this.data.name) {
                this.errors.name = 'Это поле обязательно'
                return
            }
            if(!this.data.promocode) {
                this.errors.promocode = 'Это поле обязательно'
                return
            }
            if(!this.data.count_activate) {
                this.errors.count_activate = 'Это поле обязательно'
                return
            }
            if(!this.data.method) {
                this.errors.method = 'Это поле обязательно'
                return
            }
            if(!this.data.type) {
                this.errors.type = 'Это поле обязательно'
                return
            }
            if(!this.data.action) {
                this.errors.action = 'Это поле обязательно'
                return
            }
            if(!this.data.wager) {
                this.errors.wager = 'Это поле обязательно'
                return
            }
            if(!this.data.expire.startDate) {
                this.errors.expire = 'Это поле обязательно'
                return
            }
            if(!this.data.expire_in_days) {
                this.errors.expire_in_days = 'Это поле обязательно'
                return
            }
            if(!this.data.status) {
                this.errors.status = 'Это поле обязательно'
                return
            }

            if(this.data.type.key == 1) {

                for (let index in this.data.merchants) {
                    if(this.data.action.key != 2 && !this.data.merchants[index].min_deposit) {
                        this.errors.merchants[index].min_deposit = 'Это поле обязательно'     
                        this.refreshMerchantData()                   
                        return
                    }

                    if(!this.data.merchants[index].amount) {
                        this.errors.merchants[index].amount = 'Это поле обязательно'
                        this.refreshMerchantData()   
                        return
                    }
                }

            } else if(this.data.type.key == 2) {

                if(!this.data.game) {
                    this.errors.game = 'Это поле обязательно'
                    return
                }

                if(!this.data.freespins_number) {
                    this.errors.freespins_number = 'Это поле обязательно'
                    return
                }

                for (let index in this.data.merchants) {
                    if(!this.data.merchants[index]) { 
                        this.errors.merchants[index] = 'Это поле обязательно'
                        this.refreshMerchantData()
                        return
                    }
                }

            } else if(this.data.type.key == 3) {

                if(!this.data.percent) {
                    this.errors.percent = 'Это поле обязательно'
                    return
                }

                for (let index in this.data.merchants) {
                    if(!this.data.merchants[index].min_deposit) {
                        this.errors.merchants[index].min_deposit = 'Это поле обязательно'
                        this.refreshMerchantData()   
                        return
                    }

                    if(!this.data.merchants[index].max_deposit) {
                        this.errors.merchants[index].max_deposit = 'Это поле обязательно'
                        this.refreshMerchantData()  
                        return
                    }
                }

            }

            var platforms = []

            if(this.data.platforms) {
                for (let item of this.data.platforms) {
                    platforms.push(item.key)
                }
            }

            if(!platforms.length) {
                this.errors.platforms = 'Это поле обязательно'
                return
            }


            var data = {
                is_partner: this.data.is_partner,
                name: this.data.name,
                promocode: this.data.promocode,
                count_activate: this.data.count_activate,
                action: this.data.action.key,
                method: this.data.method.key,
                wager: this.data.wager,
                expire: this.data.expire.startDate,
                expire_in_days: this.data.expire_in_days,
                platforms: platforms,
                status: this.data.status.key,
                merchants: this.data.merchants
            }
            var type = null

            if(this.data.type.key == 1) {
                type = 'vaucher'
            } else if(this.data.type.key == 2) {
                type = 'freespin'
                data.game = this.data.game.id
                data.freespins_number = this.data.freespins_number
            } else if(this.data.type.key == 3) {
                type = 'percent'
                data.percent = this.data.percent
            }
            
            this.btn_preloader = true
            this.$axios.post(`/promocodes/create/${type}`, JSON.stringify(data))
            .then((response) => {
                this.btn_preloader = false
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":
                        this.$router.push({name: 'PromocodesItems'})
                        this.$toast.success(response.message)

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                this.btn_preloader = false
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errorByPatch(this.errors, response.field, response.message)
                        this.refreshMerchantData()
                    } else {
                        this.$toast.error(response.message)
                    }
                }
            })
        },
        searchGames (search, loading) {
            this.utils.games = []
            if(search.length >= 3) {
                loading(true)
                 this.$axios.post('/games/search/identificator', JSON.stringify({
                    search: search,
                    has_freespins: 1
                 }))
                .then((response) => {
                    response = response.data
                    switch (response.status) {
                        case "error":
                            this.$toast.error(response.message)
                            break
                        case "success":
                            loading(false)
                            for (let game of response.data) {
                                this.utils.games.push({
                                    id: game.id,
                                    uuid: game.uuid,
                                    name: game.name,
                                    image: game.image,
                                    is_mobile: game.is_mobile,
                                    is_desktop: game.is_desktop
                                })
                            }

                            this.setUser(response.user)
                            break
                        default:
                            this.$toast.error('Unknown error')
                    }
                }).catch((error) => {
                    let response = error.response.data
                    this.setUser(response.user)

                    if('status' in response && response.status == 'error') {
                        this.$toast.error(response.message)
                    }
                })
            }
        },
        async getMerchants () {
            await this.$axios.post(`/merchants/items`)
            .then((response) => {
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":
                        this.utils.merchants = response.data
                        this.changeType()
                        this.refreshMerchantData()

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errors[response.field] = response.message
                    } else {
                        this.$toast.error(response.message)
                    }
                }
            })
        },
        openBetMerchant (merchant) {
            this.$axios.post(`/promotions/freespins/bets`, JSON.stringify({
                merchant: {
                    id: merchant.id,
                    currency: merchant.currency
                },
                game: {
                    id: this.data.game.id,
                    uuid: this.data.game.uuid
                }
            }))
            .then((response) => {
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":

                        for(let item in response.data) {
                            response.data[item].total_bet_per_line = response.data[item].total_bet * response.data[item].denomination
                        }

                        this.utils.freespins_bets[merchant.id] = response.data
                        this.refreshMerchantData()  

                        this.setUser(response.user) 
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errors[response.field] = response.message
                    } else {
                        this.$toast.error(response.message)
                    }
                }
            })
        },
        changeType () {
            this.data.merchants = {}
            this.utils.freespins_bets = []
            this.errors.merchants = {}

            if(this.data.type.key == 1) {
                for (let item of this.utils.merchants) {
                    this.data.merchants[item.id] = {
                        min_deposit: null,
                        amount: null
                    }

                    this.errors.merchants[item.id] = {
                        min_deposit: false,
                        amount: false
                    }
                }
            } else if(this.data.type.key == 2) {
                for (let item of this.utils.merchants) {
                    this.data.merchants[item.id] = {
                        min_deposit: null,
                        bet: null
                    }

                    this.utils.freespins_bets[item.id] = []

                    this.errors.merchants[item.id] = {
                        min_deposit: false,
                        bet: false
                    }
                }
            } else if(this.data.type.key == 3) {
                for (let item of this.utils.merchants) {
                    this.data.merchants[item.id] = {
                        min_deposit: null,
                        max_deposit: null
                    }

                    this.errors.merchants[item.id] = {
                        min_deposit: false,
                        max_deposit: false
                    }
                }
            }

            if(this.data.action.key == 2) {
                this.changeAction()
            }
        },
        changeAction() {

            if(this.data.action.key == 2) {
                for (let item of this.utils.merchants) {
                    this.data.merchants[item.id].min_deposit = String(0)
                    this.errors.merchants[item.id].min_deposit = false
                }

                this.utils.types = [
                    {
                        name: 'Ваучер',
                        key: 1
                    },
                    /*{
                        name: 'Бесплатные вращения',
                        key: 2
                    }*/
                ]

                if(this.data.type.key == 3) {
                   this.data.type = this.utils.types[0]
                }
            } else {
                for (let item of this.utils.merchants) {
                    this.data.merchants[item.id].min_deposit = null
                    this.errors.merchants[item.id].min_deposit = false
                }

                this.utils.types = [
                    {
                        name: 'Ваучер',
                        key: 1
                    },
                    /*{
                        name: 'Бесплатные вращения',
                        key: 2
                    },*/
                    {
                        name: 'Кэш Бонус',
                        key: 3
                    }
                ]
            }

        },
        errorByPatch (layer, path, value) {
            path = path.split('.')

            path.forEach((value, index) => {
                if (!isNaN(value)) {
                    path[index] = parseInt(value)
                }
            })

            for (var i = 0; i < path.length; i++) {
                if (value != null && i + 1 === path.length) {
                    layer[path[i]] = value
                }
                layer = layer[path[i]]
            }
            return layer
        },
        refreshMerchantData () {
            this.refresh_merchant_data = String(Date.now())
        },
        promocodeUpperCase () {
            this.data.promocode = this.data.promocode.toUpperCase()
        }
    },
    async beforeMount() {
        await this.getMerchants()
    }
}
</script>

<style lang="css" scoped>
    ::v-deep .vs__clear {
        display: none;
    }
</style>
