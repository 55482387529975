<template>
    <main class="main">
        <div class="container-fluid px-0" data-layout="container">

            <aside-main/>

            <header-main/>

            <div class="content">

                <div class="card-body p-0 spinner-center" v-if="preloader">
                    <div class="p-4 code-to-copy">
                        <div class="spinner-border" role="status"></div>
                    </div>
                </div>

                <form class="mb-9" v-if="!preloader">
                    <div class="row justify-content-between align-items-end g-3 mb-5">
                        <div class="col-12 col-sm-auto col-xl-8">
                            <h2>KYC документы {{ $props.id }}</h2>
                        </div>
                    </div>
                    <div class="row g-5">
                        <div class="col-xl-8">
                            <h4 class="fs-1 mb-4">Основная информация</h4>
                            <div class="row g-3">
                            	<div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <input 
                                        	disabled 
                                            v-model="data.user_email"
                                            class="form-control" 
                                            type="text" 
                                            placeholder="Пользователь"/>
                                        <label>Пользователь</label>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating v-select-medium">
                                        <vue-select
                                        	disabled
                                            v-model="data.country"
                                            :options="utils.countries"
                                            placeholder="Страна"
                                            label="name">
                                            <template v-slot:no-options="{ search, searching }">
                                              <template v-if="searching">
                                                 Ничего не найдено по запросу <em>{{ search }}</em>.
                                              </template>
                                            </template>
                                        </vue-select>
                                        <label>Страна</label>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating v-select-medium">
                                        <vue-select
                                        	disabled
                                            v-model="data.type"
                                            :options="utils.doc_types"
                                            placeholder="Тип документа"
                                            label="name">
                                            <template v-slot:no-options="{ search, searching }">
                                              <template v-if="searching">
                                                 Ничего не найдено по запросу <em>{{ search }}</em>.
                                              </template>
                                            </template>
                                        </vue-select>
                                        <label>Тип документа</label>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <input 
                                        	disabled 
                                            v-model="data.doc_id"
                                            class="form-control" 
                                            type="text" 
                                            placeholder="ID документа"/>
                                        <label>ID документа</label>
                                    </div>
                                </div>
                                
                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <input 
                                        	disabled 
                                            v-model="data.expire"
                                            class="form-control" 
                                            type="text" 
                                            placeholder="Срок годности"/>
                                        <label>Срок годности</label>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <input 
                                        	disabled 
                                            v-model="data.last_name"
                                            class="form-control" 
                                            type="text" 
                                            placeholder="Фамилия"/>
                                        <label>Фамилия</label>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <input 
                                        	disabled 
                                            v-model="data.first_name"
                                            class="form-control" 
                                            type="text" 
                                            placeholder="Имя"/>
                                        <label>Имя</label>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating v-select-medium">
                                        <vue-select
                                        	disabled
                                            v-model="data.sex"
                                            :options="utils.sex"
                                            placeholder="Пол"
                                            label="name">
                                            <template v-slot:no-options="{ search, searching }">
                                              <template v-if="searching">
                                                 Ничего не найдено по запросу <em>{{ search }}</em>.
                                              </template>
                                            </template>
                                        </vue-select>
                                        <label>Пол</label>
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div class="col-xl-4">
                            <h4 class="fs-1 mb-4">Действия</h4>

                        	<div class="row g-1 mb-5">
                        		<div class="col-12">
                        			<a
                                        v-if="!data.user_kyc"
                                        @click="approveKyc(data.user_id)"
                                        v-tippy="{arrow : true, arrowType: 'round'}"
                                        content="Верифицировать KYC пользователя"
                                        class="btn btn-soft-warning btn-sm me-1 mb-1 mr-3">
                                        <i class="fa-regular fa-user-check"></i>
                                    </a>

                        			<a
                                        v-if="data.status == 0"
                                        @click="confirmDoc(data.id)"
                                        v-tippy="{arrow : true, arrowType: 'round'}"
                                        content="Одобрить"
                                        class="btn btn-soft-success btn-sm me-1 mb-1 mr-3">
                                        <i class="fa-solid fa-check"></i>
                                    </a>

                                    <a
	                                    v-if="data.status == 0"
	                                    @click="rejectDoc(data.id)"
	                                    v-tippy="{arrow : true, arrowType: 'round'}"
	                                    content="Отклонить"
	                                    class="btn btn-soft-danger btn-sm me-1 mb-1 mr-3">
	                                    <i class="fa-solid fa-ban"></i>
	                                </a>
                        		</div>
                        	</div>

                        	<h4 class="fs-1 mb-4">Файлы</h4>

                        	<div class="row g-1 mb-5">
                        		<div class="col-6">
                        			<a :href="`${$config.ApplicationAPI}/users/kyc/file/${data.id}?type=doc&api_token=${api_token}`" target="_blank">
                        				<img class="rounded h-100 w-100" :src="`${$config.ApplicationAPI}/users/kyc/file/${data.id}?type=doc&api_token=${api_token}`">
                        			</a>
                        		</div>

                        		<div class="col-6">
                        			<a :href="`${$config.ApplicationAPI}/users/kyc/file/${data.id}?type=selfie&api_token=${api_token}`" target="_blank">
                        				<img class="rounded h-100 w-100" :src="`${$config.ApplicationAPI}/users/kyc/file/${data.id}?type=selfie&api_token=${api_token}`">
                        			</a>
                        		</div>
                        	</div>
                                
                        </div>

                    </div>
                </form>

                <modal 
                    name="reject-kyc"
                    height="auto"
                    :shiftY="0.3"
                    :adaptive="true">
                    <form class="row g-3" v-on:submit.prevent="rejectDocConfirm">
                        <div class="col-sm-12 col-md-12">
                            <div class="form-floating">
                                <input
                                    v-model="reject.message"
                                    @input="errors.reject.message = null"
                                    :class="{'is-invalid': errors.reject.message}"
                                    placeholder="Причина отклонения"
                                    class="form-control" 
                                    type="text"/>
                                <label>Причина отклонения</label>
                                <div class="invalid-feedback">{{errors.reject.message}}</div>
                            </div>
                        </div>
                        <div class="col-sm-3 col-md-3">
                            <button class="btn btn-danger px-5 w-100 text-nowrap" type="submit" :class="{'disabled': btn_preloader}">
                                <span class="spinner-border spinner-border-sm spinner-border-custom" role="status" v-if="btn_preloader"></span>
                                <span>Отклонить</span>
                            </button>
                        </div>
                    </form>
                </modal>
                
                <footer-main/>

            </div>

        </div>
    </main>
</template>

<script>
export default {
    props: ['id'],
    data () {
        return {
        	api_token: localStorage.getItem('token'),
            preloader: true,
            btn_preloader: false,
            data: {
            	id: null,
            	user_id: null,
            	user_email: null,
            	user_kyc: null,
                country: null,
                type: null,
                doc_id: null,
                expire: null,
                last_name: null,
                first_name: null,
                sex: null,
                doc: null,
                selfie: null,
                status: null,
            },
            reject: {

            },
            utils: {
                countries: [],
                statuses: [
                    {
                        name: 'Выполнен',
                        key: 1
                    },
                    {
                        name: 'В ожидании',
                        key: 0
                    },
                    {
                        name: 'Отклонен',
                        key: 2
                    },
                ],
                doc_types: [
                    {
                        name: 'Паспорт',
                        key: 'passport'
                    },
                    {
                        name: 'Водительские права',
                        key: 'driver_id'
                    },
                    {
                        name: 'Удостоверения личности',
                        key: 'id_card'
                    }
                ],
                sex: [
                    {
                        name: 'Мужской',
                        key: 1
                    },
                    {
                        name: 'Женский',
                        key: 2
                    }
                ]
            },
            errors: {
                reject: {
                    message: false
                }
            }
        }
    },
    methods: {
        async getItem () {
            await this.$axios.post('/users/kyc/item/' + this.$props.id)
            .then((response) => {
                this.preloader = false
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':

                    	this.data = {
                    		id: response.data.id,
                    		user_id: response.data.user_id,
                    		user_email: response.data.user_email,
                    		user_kyc: response.data.user_kyc,
                    		country: this.utils.countries.find(row => row.key == response.data.country),
                    		type: this.utils.doc_types.find(row => row.key == response.data.type),
                    		doc_id: response.data.doc_id,
                    		expire: response.data.expire,
                    		last_name: response.data.last_name,
                    		first_name: response.data.first_name,
                    		sex: this.utils.sex.find(row => row.key == response.data.sex),
                    		doc: response.data.doc,
                    		selfie: response.data.selfie,
                    		status: response.data.status
                    	}

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })     
        },
        async getCountries () {
            await this.$axios.post('/utils/countries/items')
            .then((response) => {
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':

                        for(let item in response.data) {
                            this.utils.countries.push({
                                name: response.data[item],
                                key: item
                            })
                        }

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                console.log(error)
                let response = error.response.data
                this.setUser(response.user)
                
                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })     
        },
        approveKyc (user_id) {
            this.$confirm('Вы уверены?').then(() => {
                this.$axios.post(`/users/kyc/approve/${user_id}`)
                .then((response) => {
                    response = response.data
                    switch (response.status) {
                        case 'error':
                            this.$toast.error(response.message)
                            break
                        case 'success':
                            this.setNotificationKyc(this.$store.state.notifications.kyc - 1)
                            this.$toast.success(response.message)

                            this.getItem()

                            this.setUser(response.user)
                            break
                        default:
                            this.$toast.error('Unknown error')
                    }
                }).catch((error) => {
                    let response = error.response.data
                    this.setUser(response.user)

                    if('status' in response && response.status == 'error') {
                        this.$toast.error(response.message)
                    }
                })
            })
        },
        confirmDoc (id) {
            this.$confirm('Вы уверены?').then(() => {
                this.$axios.post('/users/kyc/accept', JSON.stringify({
                    id: id
                }))
                .then((response) => {
                    response = response.data
                    switch (response.status) {
                        case 'error':
                            this.$toast.error(response.message)
                            break
                        case 'success':
                            this.setNotificationKyc(this.$store.state.notifications.kyc - 1)
                            this.$toast.success(response.message)

                            this.getItem()

                            this.setUser(response.user)
                            break
                        default:
                            this.$toast.error('Unknown error')
                    }
                }).catch((error) => {
                    let response = error.response.data
                    this.setUser(response.user)

                    if('status' in response && response.status == 'error') {
                        this.$toast.error(response.message)
                    }
                })
            })
        },
        rejectDoc (id) {
            this.$confirm('Вы уверены?').then(() => {
            	this.reject.id = id
                this.$modal.show('reject-kyc')
            })
        },
        rejectDocConfirm () {
            if(!this.reject.message) {
                this.errors.reject.message = 'Это поле обязательно'
                return
            }
            this.btn_preloader = true
            this.$axios.post('/users/kyc/reject', JSON.stringify({
                id: this.reject.id,
                reject_message: this.reject.message
            }))
            .then((response) => {
                this.btn_preloader = false
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':
                        this.setNotificationKyc(this.$store.state.notifications.kyc - 1)
                        this.$modal.hide('reject-kyc')
                        this.$toast.success(response.message)

                        this.getItem()

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                this.btn_preloader = false
                let response = error.response.data
                this.setUser(response.user)
                
                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errors[response.field] = response.message
                    } else {
                        this.$toast.error(response.message)
                    }
                }
            })
        },
    },
    async beforeMount() {
        await this.getCountries()
        await this.getItem()
    }
}
</script>

<style lang="css" scoped>
    ::v-deep .vs__clear {
        display: none;
    }
</style>
