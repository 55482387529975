<template>
    <main class="main">
        <div class="container-fluid px-0" data-layout="container">

        	<aside-main/>

            <header-main/>

            <div class="content">
                <div class="row g-3 mb-3">
                    <div class="card-header p-2 mt-2 d-flex flex-between-baseline" style="margin-bottom: -20px;">
                        <div class="d-flex align-items-center">
                            <h2 class="text-bold text-1100 mb-5">
                                Пользователи
                                <span class="fw-normal text-700 ms-2">({{items_count}})</span>
                            </h2>
                        </div>

                        <div class="d-flex">
                            <button
                                :class="{'disabled': btn_preloader}"
                                @click="exportExcel"
                                type="button"
                                class="btn btn-primary me-1 mb-1 float_right"
                                style="margin-right: 0!important">
                                <span class="spinner-border spinner-border-sm spinner-border-custom" role="status" v-if="btn_preloader"></span>
                                <span>Экспорт в Excel</span>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="row align-items-center justify-content-between g-3 mb-3"  v-if="!preloader">
                    <div class="col-sm-4 col-md-4">
                        <div class="form-icon-container">
                            <date-range-picker
                                @update="changeSearch"
                                :locale-data="datepiker"
                                :opens="pikerparams.opens"
                                :always-show-calendars="pikerparams.showcalendars"
                                :close-on-esc="pikerparams.closeonesc"
                                :auto-apply="pikerparams.autoapply"
                                :time-picker="pikerparams.timePicker"
                                :time-picker24-hour="pikerparams.timePicker24Hour"
                                v-model="search.date"
                                class="daterange-picker-medium">
                                <template #ranges="ranges">
                                  <div class="ranges">
                                    <ul>
                                      <li 
                                        v-for="(range, name, key) in pikerranges" 
                                        :key="name" 
                                        @click="ranges.clickRange(range, key)">
                                            {{name}}
                                      </li>
                                    </ul>
                                  </div>
                                </template>
                            </date-range-picker>
                        </div>
                    </div>

                    <div class="col-sm-4 col-md-4">
                        <div class="form-icon-container">
                            <vue-select
                                @input="changeSearch"
                                v-model="search.language"
                                :options="utils.languages"
                                placeholder="Язык"
                                label="name">
                                <template v-slot:no-options="{ search, searching }">
                                  <template v-if="searching">
                                     Ничего не найдено по запросу <em>{{ search }}</em>.
                                  </template>
                                </template>
                            </vue-select>
                        </div>
                    </div>

                    <div class="col-sm-4 col-md-4">
                        <div class="form-icon-container">
                            <vue-select
                                @input="changeSearch(), changeSegment()"
                                v-model="search.segment"
                                :options="utils.segments"
                                placeholder="Сегмент"
                                label="name">
                                <template v-slot:no-options="{ search, searching }">
                                  <template v-if="searching">
                                     Ничего не найдено по запросу <em>{{ search }}</em>.
                                  </template>
                                </template>
                            </vue-select>
                        </div>
                    </div>

                    <div class="col-sm-12 col-md-12" v-if="value_placeholder">
                        <div class="form-icon-container">
                            <input
                                @change="changeSearch"
                                v-model="search.value"
                                type="text" 
                                :placeholder="value_placeholder" 
                                class="form-control form-icon-input">
                            <span class="fa-regular fa-magnifying-glass text-500 fs--1 form-icon"></span>
                        </div>
                    </div>
                </div>

                <div class="card-body p-0 spinner-center" v-if="preloader">
                    <div class="p-4 code-to-copy">
                        <div class="spinner-border" role="status"></div>
                    </div>
                </div>


                <div class="mx-n4 mx-lg-n6 px-4 px-lg-6 mb-9 bg-white border-y border-300 mt-2 position-relative top-1" v-show="!preloader">
                    <div class="table-responsive scrollbar ms-n1 ps-1">
                    
                        <div class="table table-responsive-small">
                            <datatable
                                :filter="search.search_change"
                                :columns="columns" 
                                :data="getItems"
                                :per-page="pager.per_page">
                                <template scope="{ row, columns }">
                                    <tr>
                                        <td>{{ row.id }}</td>
                                        <td>
                                            <router-link
                                                :to="{name: 'UsersItem', params: {id: row.id}}">
                                                {{ row.email }}
                                            </router-link>
                                        </td>
                                        <td>{{ row.username }}</td>
                                        <td>{{ row.phone }}</td>
                                        <td>{{ parseFloat(row.balance).toFixed(2) }}</td>
                                        <td>{{ $moment(row.created_at).format('YYYY-MM-DD H:mm:ss') }}</td>
                                        <td>{{ $moment(row.updated_at).format('YYYY-MM-DD H:mm:ss') }}</td>
                                    </tr>
                                </template>
                            </datatable>
                        </div>

                        <div class="col-auto d-flex">
                            <ul class="mb-3 pagination">
                                <datatable-pager v-model="pager.page" type="abbreviated"></datatable-pager>
                            </ul>
                        </div>

                    </div>
                </div>
                
                <footer-main/>

            </div>

        </div>
    </main>
</template>

<script>
export default {
    data () {
        return {
            btn_preloader: false,
            preloader: true,
            pager: {
                per_page: 25,
                page: 0
            },
            items_count: 0,
            value_placeholder: null,
            search: {
                search_change: null,
                language: {
                    name: 'Русский',
                    key: 'ru'
                },
                segment: {
                    name: 'Регистрация',
                    key: 1
                },
                value: null,
                date: {
                    startDate: this.$moment().set({date: this.$moment().get('date') - 30}).format('Y-MM-DD HH:mm:ss'),
                    endDate: this.$moment().format('Y-MM-DD HH:mm:ss')
                }
            },
            columns: [
                {label: 'ID'},
                {label: 'Email'},
                {label: 'Username'},
                {label: 'Телефон'},
                {label: 'Баланс'},
                {label: 'Дата реги.'},
                {label: 'Последний онлайн'},
            ],
            utils: {
                segments: [
                    {
                        name: 'Регистрация',
                        key: 1
                    },
                    {
                        name: 'Использовал только бонус',
                        key: 2
                    },
                    {
                        name: 'Сделал 1 фд',
                        key: 3
                    },
                    {
                        name: 'Сделал 1 рд',
                        key: 4
                    },
                    {
                        name: 'Не введ номер телефона',
                        key: 5
                    },
                    {
                        name: 'Не заходил по времени на сайт',
                        key: 6
                    },
                    {
                        name: 'Внесенные депозиты',
                        key: 7
                    },
                    {
                        name: 'Сомоограничение',
                        key: 8
                    },
                ],
                confirmations: {
                    email: [
                        {
                            name: 'Верифицирован',
                            key: true
                        },
                        {
                            name: 'Не верифицирован',
                            key: false
                        }
                    ],
                    kyc: [
                        {
                            name: 'Верифицирован',
                            key: true
                        },
                        {
                            name: 'Не верифицирован',
                            key: false
                        }
                    ]
                },
                languages: []
            },
            datepiker: {
                direction: 'ltr',
                format: 'yyyy.mm.dd HH:MM',
                separator: ' - ',
                applyLabel: 'Применить',
                cancelLabel: 'Отмена',
                weekLabel: 'W',
                customRangeLabel: 'Настраиваемый диапазон',
                daysOfWeek: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
                monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
                firstDay: 0
            },
            pikerparams: {
                showcalendars: true,
                closeonesc: true,
                autoapply: false,
                timePicker: true,
                timePicker24Hour: true,
                opens: 'right'
            },
            pikerranges: {
                'Один день': [this.$moment().set({date: this.$moment().get('date') - 1}).toDate(), this.$moment().toDate()],
                'Одна неделя': [this.$moment().set({date: this.$moment().get('date') - 7}).toDate(), this.$moment().toDate()],
                'Один месяц': [this.$moment().set({date: this.$moment().get('date') - 30}).toDate(), this.$moment().toDate()],
                'Два месяца': [this.$moment().set({date: this.$moment().get('date') - 60}).toDate(), this.$moment().toDate()],
                'Три месяца': [this.$moment().set({date: this.$moment().get('date') - 90}).toDate(), this.$moment().toDate()],
                'Пол года': [this.$moment().set({date: this.$moment().get('date') - 180}).toDate(), this.$moment().toDate()],
                'Один год': [this.$moment().set({date: this.$moment().get('date') - 365}).toDate(), this.$moment().toDate()]
            }
        }
    },
    methods: {
        async getItems ({ sortBy, sortDir, perPage, page }) {
            this.preloader = true
            var items = await this.$axios.post('/users/segmentation/items', JSON.stringify({
                pager: {
                    per_page: this.pager.per_page,
                    page: page - 1
                },
                sort: {
                    by: sortBy,
                    dir: sortDir
                },
                search: {
                    language: this.search.language.key,
                    segment: this.search.segment.key,
                    value: this.search.value,
                    date: {
                        start: this.$moment(this.search.date.startDate).format('YYYY-MM-DD H:mm:ss'),
                        expire: this.$moment(this.search.date.endDate).format('YYYY-MM-DD H:mm:ss')
                    }
                }
            }))
            .then((response) => {
                this.preloader = false
                this.setUser(response.data.user)
                return response.data
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })

            if(items && items.status == 'success') {
                this.items_count = items.data.count
                return {
                    rows: items.data.items,
                    totalRowCount: items.data.count
                }
            }

            return {
                rows: [],
                totalRowCount: 0
            }
        },
        getLanguages () {
            this.$axios.post('utils/languages/items')
            .then((response) => {
                this.preloader = false
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":
                        
                        for (let item in response.data) {
                            this.utils.languages.push({
                                name: response.data[item],
                                key: item
                            })
                        }

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errors[response.field] = response.message
                    } else {
                        this.$toast.error(response.message)
                    }
                }

                if(error.response.status == 404) {
                    this.$router.push({name: 'BannersList'})
                }
            })
        },
        exportExcel () {
            this.btn_preloader = true
            this.$axios({
                url: '/users/segmentation/export',
                method: 'POST',
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    search: {
                        language: this.search.language.key,
                        segment: this.search.segment.key,
                        value: this.search.value,
                        date: {
                            start: this.$moment(this.search.date.startDate).format('YYYY-MM-DD H:mm:ss'),
                            expire: this.$moment(this.search.date.endDate).format('YYYY-MM-DD H:mm:ss')
                        }
                    }
                }),
            }).then((response) => {
                this.btn_preloader = false
                const url = URL.createObjectURL(new Blob([response.data], {
                    type: 'application/vnd.ms-excel'
                }))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'Segmentation.xlsx')
                document.body.appendChild(link)
                link.click()
            }).catch((error) => {
                this.btn_preloader = false
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })   
        },
        changeSegment () {
            if(this.search.segment.key == 6) {
                this.value_placeholder = 'Значение (в часах)'
                this.search.value = 24
            } else if(this.search.segment.key == 7) {
                this.value_placeholder = 'Значение'
                this.search.value = 1
            } else {
                this.value_placeholder = null
            }
        },
        changeSearch () {
            this.search.search_change = String(Date.now())
        }
    },
    beforeMount() {
        this.getLanguages()
    }
}
</script>

<style lang="css" scoped>
    ::v-deep .vs__clear {
        display: none;
    }
</style>