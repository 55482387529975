<template>
    <main class="main">
        <div class="container-fluid px-0" data-layout="container">

            <aside-main/>

            <header-main/>

            <div class="content">
                <form class="mb-9" v-on:submit.prevent="createItem">
                    <div class="row justify-content-between align-items-end g-3 mb-5">
                        <div class="col-12 col-sm-auto col-xl-8">
                            <h2>Создать Мерчант</h2>
                        </div>
                    </div>
                    <div class="row g-5">
                        <div class="col-xl-12">
                            <div class="row g-3">
                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <input
                                            v-model="data.name"
                                            @input="errors.name = null"
                                            :class="{'is-invalid': errors.name}"
                                            placeholder="Название"
                                            class="form-control" 
                                            type="text"/>
                                        <label>Название</label>
                                        <div class="invalid-feedback">{{errors.name}}</div>
                                    </div>
                                </div>
                                
                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <input
                                            v-model="data.currency"
                                            @input="errors.currency = null"
                                            :class="{'is-invalid': errors.currency}"
                                            placeholder="Код валюты"
                                            class="form-control" 
                                            type="text"/>
                                        <label>Код валюты</label>
                                        <div class="invalid-feedback">{{errors.currency}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <input
                                            v-model="data.currency_sign"
                                            @input="errors.currency_sign = null"
                                            :class="{'is-invalid': errors.currency_sign}"
                                            placeholder="Знак валюты"
                                            class="form-control" 
                                            type="text"/>
                                        <label>Знак валюты</label>
                                        <div class="invalid-feedback">{{errors.currency_sign}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <input
                                            v-model="data.min_deposit"
                                            @input="errors.min_deposit = null"
                                            :class="{'is-invalid': errors.min_deposit}"
                                            placeholder="Мин. сумма депозита"
                                            class="form-control" 
                                            type="text"/>
                                        <label>Мин. сумма депозита</label>
                                        <div class="invalid-feedback">{{errors.min_deposit}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <input
                                            v-model="data.min_withdrawal"
                                            @input="errors.min_withdrawal = null"
                                            :class="{'is-invalid': errors.min_withdrawal}"
                                            placeholder="Мин. сумма выплаты"
                                            class="form-control" 
                                            type="text"/>
                                        <label>Мин. сумма выплаты</label>
                                        <div class="invalid-feedback">{{errors.min_withdrawal}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <input
                                            v-model="data.rate"
                                            @input="errors.rate = null"
                                            :class="{'is-invalid': errors.rate}"
                                            placeholder="Курс к доллару"
                                            class="form-control" 
                                            type="text"/>
                                        <label>Курс к доллару</label>
                                        <div class="invalid-feedback">{{errors.rate}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <input
                                            v-model="data.min_cashback"
                                            @input="errors.min_cashback = null"
                                            :class="{'is-invalid': errors.min_cashback}"
                                            placeholder="Мин. сумма кэшбека"
                                            class="form-control" 
                                            type="text"/>
                                        <label>Мин. сумма кэшбека</label>
                                        <div class="invalid-feedback">{{errors.min_cashback}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <input
                                            v-model="data.min_balance_cancel_promo"
                                            @input="errors.min_balance_cancel_promo = null"
                                            :class="{'is-invalid': errors.min_balance_cancel_promo}"
                                            placeholder="Мин. баланс для отмены бонуса"
                                            class="form-control" 
                                            type="text"/>
                                        <label>Мин. баланс для отмены бонуса</label>
                                        <div class="invalid-feedback">{{errors.min_balance_cancel_promo}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <input
                                            v-model="data.denomination"
                                            @input="errors.denomination = null"
                                            :class="{'is-invalid': errors.denomination}"
                                            placeholder="Множитель ставок"
                                            class="form-control" 
                                            type="text"/>
                                        <label>Множитель ставок</label>
                                        <div class="invalid-feedback">{{errors.denomination}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating v-select-medium" :class="{'v-select-error': errors.status}">
                                        <vue-select
                                            @input="errors.status = null"
                                            v-model="data.status"
                                            :options="utils.statuses" 
                                            placeholder="Статус"
                                            label="name">
                                            <template v-slot:no-options="{ search, searching }">
                                              <template v-if="searching">
                                                 Ничего не найдено по запросу <em>{{ search }}</em>.
                                              </template>
                                            </template>
                                        </vue-select>
                                        <label>Статус</label>
                                        <div class="invalid-feedback">{{errors.status}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-3 col-md-3">
                                    <button class="btn btn-primary px-5 w-100 text-nowrap" type="submit" :class="{'disabled': btn_preloader}">
                                        <span class="spinner-border spinner-border-sm spinner-border-custom" role="status" v-if="btn_preloader"></span>
                                        <span>Создать</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                
                <footer-main/>

            </div>

        </div>
    </main>
</template>

<script>
export default {
    data () {
        return {
            btn_preloader: false,
            data: {
                name: null,
                currency: null,
                currency_sign: null,
                min_deposit: null,
                min_withdrawal: null,
                rate: null,
                min_cashback: null,
                min_balance_cancel_promo: null,
                denomination: null,
                status: {
                    name: 'Отключен',
                    key: 0
                }
            },
            errors: {
                name: false,
                currency: false,
                currency_sign: false,
                min_deposit: false,
                min_withdrawal: false,
                rate: false,
                min_cashback: false,
                min_balance_cancel_promo: false,
                denomination: false,
                status: false
            },
            utils: {
                statuses: [
                    {
                        name: 'Включен',
                        key: 1
                    },
                    {
                        name: 'Отключен',
                        key: 0
                    }
                ]
            }
        }
    },
    methods: {
        createItem () {
            if(!this.data.name) {
                this.errors.name = 'Это поле обязательно'
                return
            }
            if(!this.data.currency) {
                this.errors.currency = 'Это поле обязательно'
                return
            }
            if(!this.data.currency_sign) {
                this.errors.currency_sign = 'Это поле обязательно'
                return
            }
            if(!this.data.min_deposit) {
                this.errors.min_deposit = 'Это поле обязательно'
                return
            }
            if(!this.data.min_withdrawal) {
                this.errors.min_withdrawal = 'Это поле обязательно'
                return
            }
            if(!this.data.rate) {
                this.errors.rate = 'Это поле обязательно'
                return
            }
            if(!this.data.min_cashback) {
                this.errors.min_cashback = 'Это поле обязательно'
                return
            }
            if(!this.data.min_balance_cancel_promo) {
                this.errors.min_balance_cancel_promo = 'Это поле обязательно'
                return
            }
            if(!this.data.denomination) {
                this.errors.denomination = 'Это поле обязательно'
                return
            }
            if(!this.data.status) {
                this.errors.status = 'Это поле обязательно'
                return
            }
            
            this.btn_preloader = true
            this.$axios.post('/merchants/create', JSON.stringify({
                name: this.data.name,
                currency: this.data.currency,
                currency_sign: this.data.currency_sign,
                min_deposit: this.data.min_deposit,
                min_withdrawal: this.data.min_withdrawal,
                rate: this.data.rate,
                min_cashback: this.data.min_cashback,
                min_balance_cancel_promo: this.data.min_balance_cancel_promo,
                denomination: this.data.denomination,
                status: this.data.status.key
            }))
            .then((response) => {
                this.btn_preloader = false
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":
                        this.$router.push({name: 'MerchantsList'})
                        this.$toast.success(response.message)

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errors[response.field] = response.message
                    }
                }
            })
        }
    }
}
</script>

<style lang="css" scoped>
    ::v-deep .vs__clear {
        display: none;
    }
</style>
