<template>
    <main class="main">
        <div class="container-fluid px-0" data-layout="container">

            <aside-main/>

            <header-main/>

            <div class="content">

                <div class="align-items-center justify-content-between g-3" v-if="!preloader">
                    <div class="row g-3 mb-6">
                        <div class="col-12 col-lg-12">
                            <div class="card h-100">
                                <div class="card-body">
                                    <div class="border-bottom border-dashed border-300 pb-4">
                                        <div class="row align-items-center g-3 g-sm-5 text-center text-sm-start">
                                            <div class="col-12 col-sm-auto">
                                                <div class="avatar avatar-5xl">
                                                    <img class="rounded-circle" :src="`${$config.ApplicationStaticURL}/icos/${data.image}`" alt="" />
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-auto flex-1">
                                                <h3>{{ data.name }}</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex flex-between-center pt-4">
                                        <div>
                                            <h6 class="mb-2 text-800">Платформа</h6>
                                            <h6 class="fs-0 text-500 mb-0">
                                                <template v-if="data.is_mobile">
                                                    {{ utils.platforms.find(el => el.key == 'mobile').name }}
                                                </template>
                                                <template v-if="data.is_desktop">
                                                    <template v-if="data.is_mobile">
                                                        /
                                                    </template>
                                                    {{ utils.platforms.find(el => el.key == 'desktop').name }}
                                            </template>
                                            </h6>
                                        </div>
                                        <div class="text-center">
                                            <h6 class="mb-2 text-800">Кол. Игр</h6>
                                            <h6 class="fs-0 text-500 mb-0">{{ data.played }}</h6>
                                        </div>
                                        <div class="text-center">
                                            <h6 class="mb-2 text-800">Технология</h6>
                                            <h6 class="fs-0 text-500 mb-0"></h6>
                                        </div>
                                        <div class="text-end">
                                            <h6 class="mb-2 text-800">Freespins</h6>
                                            <h6 class="fs-0 text-500 mb-0"></h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <form class="mb-9" v-on:submit.prevent="updateItem">

                    <div class="card-body p-0 spinner-center" v-if="preloader">
                        <div class="p-4 code-to-copy">
                            <div class="spinner-border" role="status"></div>
                        </div>
                    </div>

                    <div class="row g-5" v-if="!preloader">
                        <div class="col-xl-12">
                            <div class="row g-3">
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-floating">
                                        <input
                                            v-model="data.name"
                                            @input="errors.name = null"
                                            :class="{'is-invalid': errors.name}"
                                            placeholder="Название"
                                            class="form-control" 
                                            type="text"/>
                                        <label>Название</label>
                                        <div class="invalid-feedback">{{errors.name}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <input
                                            disabled
                                            v-model="data.slug"
                                            @input="errors.slug = null"
                                            :class="{'is-invalid': errors.slug}"
                                            placeholder="Slug"
                                            class="form-control" 
                                            type="text"/>
                                        <label>Slug</label>
                                        <div class="invalid-feedback">{{errors.slug}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating v-select-medium" :class="{'v-select-error': errors.provider}">
                                        <vue-select
                                            @input="errors.provider = null"
                                            v-model="data.provider"
                                            :options="utils.providers" 
                                            placeholder="Провайдер"
                                            label="name">
                                            <template v-slot:no-options="{ search, searching }">
                                              <template v-if="searching">
                                                 Ничего не найдено по запросу <em>{{ search }}</em>.
                                              </template>
                                            </template>
                                        </vue-select>
                                        <label>Провайдер</label>
                                        <div class="invalid-feedback">{{errors.provider}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating v-select-medium" :class="{'v-select-error': errors.category}">
                                        <vue-select
                                            @input="errors.category = null"
                                            v-model="data.category"
                                            :options="utils.categories" 
                                            placeholder="Категория"
                                            label="name">
                                            <template v-slot:no-options="{ search, searching }">
                                              <template v-if="searching">
                                                 Ничего не найдено по запросу <em>{{ search }}</em>.
                                              </template>
                                            </template>
                                        </vue-select>
                                        <label>Категория</label>
                                        <div class="invalid-feedback">{{errors.category}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating v-select-medium" :class="{'v-select-error': errors.bonus_buy}">
                                        <vue-select
                                            @input="errors.bonus_buy = null"
                                            v-model="data.bonus_buy"
                                            :options="utils.default_answer" 
                                            placeholder="Покупная игра"
                                            label="name">
                                            <template v-slot:no-options="{ search, searching }">
                                              <template v-if="searching">
                                                 Ничего не найдено по запросу <em>{{ search }}</em>.
                                              </template>
                                            </template>
                                        </vue-select>
                                        <label>Покупная игра</label>
                                        <div class="invalid-feedback">{{errors.bonus_buy}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating v-select-medium" :class="{'v-select-error': errors.is_megaways}">
                                        <vue-select
                                            @input="errors.is_megaways = null"
                                            v-model="data.is_megaways"
                                            :options="utils.default_answer" 
                                            placeholder="Megaways"
                                            label="name">
                                            <template v-slot:no-options="{ search, searching }">
                                              <template v-if="searching">
                                                 Ничего не найдено по запросу <em>{{ search }}</em>.
                                              </template>
                                            </template>
                                        </vue-select>
                                        <label>Megaways</label>
                                        <div class="invalid-feedback">{{errors.is_megaways}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating v-select-medium" :class="{'v-select-error': errors.jackpot}">
                                        <vue-select
                                            @input="errors.jackpot = null"
                                            v-model="data.jackpot"
                                            :options="utils.default_answer" 
                                            placeholder="Jackpot"
                                            label="name">
                                            <template v-slot:no-options="{ search, searching }">
                                              <template v-if="searching">
                                                 Ничего не найдено по запросу <em>{{ search }}</em>.
                                              </template>
                                            </template>
                                        </vue-select>
                                        <label>Jackpot</label>
                                        <div class="invalid-feedback">{{errors.jackpot}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating v-select-medium" :class="{'v-select-error': errors.is_allowed_to_wager}">
                                        <vue-select
                                            @input="errors.is_allowed_to_wager = null"
                                            v-model="data.is_allowed_to_wager"
                                            :options="utils.default_answer" 
                                            placeholder="Отыгрыш бонуса"
                                            label="name">
                                            <template v-slot:no-options="{ search, searching }">
                                              <template v-if="searching">
                                                 Ничего не найдено по запросу <em>{{ search }}</em>.
                                              </template>
                                            </template>
                                        </vue-select>
                                        <label>Отыгрыш бонуса</label>
                                        <div class="invalid-feedback">{{errors.is_allowed_to_wager}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating v-select-medium" :class="{'v-select-error': errors.status}">
                                        <vue-select
                                            @input="errors.status = null"
                                            v-model="data.status"
                                            :options="utils.statuses" 
                                            placeholder="Статус"
                                            label="name">
                                            <template v-slot:no-options="{ search, searching }">
                                              <template v-if="searching">
                                                 Ничего не найдено по запросу <em>{{ search }}</em>.
                                              </template>
                                            </template>
                                        </vue-select>
                                        <label>Статус</label>
                                        <div class="invalid-feedback">{{errors.status}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating v-select-medium" :class="{'v-select-error': errors.merchants.white}">
                                        <vue-select
                                            multiple
                                            @input="errors.merchants.white = null"
                                            v-model="data.merchants.white"
                                            :options="utils.merchants" 
                                            placeholder="Мерчанты (White List)"
                                            label="name">
                                            <template v-slot:no-options="{ search, searching }">
                                              <template v-if="searching">
                                                 Ничего не найдено по запросу <em>{{ search }}</em>.
                                              </template>
                                            </template>
                                        </vue-select>
                                        <label>Мерчанты (White List)</label>
                                        <div class="invalid-feedback">{{ errors.merchants.white }}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating v-select-medium" :class="{'v-select-error': errors.merchants.black}">
                                        <vue-select
                                            multiple
                                            @input="errors.merchants.black = null"
                                            v-model="data.merchants.black"
                                            :options="utils.merchants" 
                                            placeholder="Мерчанты (Black List)"
                                            label="name">
                                            <template v-slot:no-options="{ search, searching }">
                                              <template v-if="searching">
                                                 Ничего не найдено по запросу <em>{{ search }}</em>.
                                              </template>
                                            </template>
                                        </vue-select>
                                        <label>Мерчанты (Black List)</label>
                                        <div class="invalid-feedback">{{ errors.merchants.black }}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating v-select-medium" :class="{'v-select-error': errors.countries.white}">
                                        <vue-select
                                            multiple
                                            @input="errors.countries.white = null"
                                            v-model="data.countries.white"
                                            :options="utils.countries" 
                                            placeholder="Страны (White List)"
                                            label="name">
                                            <template v-slot:no-options="{ search, searching }">
                                              <template v-if="searching">
                                                 Ничего не найдено по запросу <em>{{ search }}</em>.
                                              </template>
                                            </template>
                                        </vue-select>
                                        <label>Страны (White List)</label>
                                        <div class="invalid-feedback">{{ errors.countries.white }}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating v-select-medium" :class="{'v-select-error': errors.countries.black}">
                                        <vue-select
                                            multiple
                                            @input="errors.countries.black = null"
                                            v-model="data.countries.black"
                                            :options="utils.countries" 
                                            placeholder="Страны (Black List)"
                                            label="name">
                                            <template v-slot:no-options="{ search, searching }">
                                              <template v-if="searching">
                                                 Ничего не найдено по запросу <em>{{ search }}</em>.
                                              </template>
                                            </template>
                                        </vue-select>
                                        <label>Страны (Black List)</label>
                                        <div class="invalid-feedback">{{ errors.countries.black }}</div>
                                    </div>
                                </div>

                                <div class="col-sm-3 col-md-3">
                                    <button class="btn btn-primary px-5 w-100 text-nowrap" type="submit" :class="{'disabled': btn_preloader}">
                                        <span class="spinner-border spinner-border-sm spinner-border-custom" role="status" v-if="btn_preloader"></span>
                                        <span>Сохранить</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                
                <footer-main/>

            </div>

        </div>
    </main>
</template>

<script>
export default {
    props: ['id'],
    data () {
        return {
            preloader: true,
            btn_preloader: false,
            data: {
                merchants: {
                    white: [],
                    break: []
                },
                countries: {
                    white: [],
                    break: []
                }
            },
            errors: {
                name: false,
                slug: false,
                provider: false,
                category: false,
                bonus_buy: false,
                is_megaways: false,
                jackpot: false,
                is_allowed_to_wager: false,
                is_holiday: false,
                merchants: {
                    white: false,
                    break: false
                },
                countries: {
                    white: false,
                    break: false
                },
                status: false
            },
            utils: {
                providers: [],
                categories: [],
                merchants: [],
                countries: [],
                platforms: [{
                    name: 'Desktop',
                    key: 'desktop'
                },
                {
                    name: 'Mobile',
                    key: 'mobile'
                }],
                statuses: [
                    {
                        name: 'Включена',
                        key: 1
                    },
                    {
                        name: 'Отключена',
                        key: 0
                    }
                ],
                default_answer: [{
                    name: 'Да',
                    key: 1
                },
                {
                    name: 'Нет',
                    key: 0
                }],
            }
        }
    },
    methods: {
        getItem () {
            this.$axios.post(`/games/item/${this.$props.id}`)
            .then((response) => {
                this.preloader = false
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":
                        this.data = response.data
                        this.data.provider = this.utils.providers.find(row => row.id == this.data.provider)
                        this.data.category = this.utils.categories.find(row => row.id == this.data.category)
                        //this.data.platform = this.utils.platforms.find(row => row.key == this.data.platform).name
                        //this.data.has_freespins = this.utils.default_answer.find(row => row.key == this.data.has_freespins).name
                        this.data.bonus_buy = this.utils.default_answer.find(row => row.key == this.data.bonus_buy)
                        this.data.is_megaways = this.utils.default_answer.find(row => row.key == this.data.is_megaways)
                        this.data.jackpot = this.utils.default_answer.find(row => row.key == this.data.jackpot)
                        this.data.is_allowed_to_wager = this.utils.default_answer.find(row => row.key == this.data.is_allowed_to_wager)
                        //this.data.is_holiday = this.utils.default_answer.find(row => row.key == this.data.is_holiday)
                        this.data.status = this.utils.statuses.find(row => row.key == this.data.status)

                        var merchants = response.data.merchants
                        var countries = response.data.countries

                        this.data.merchants = {
                            white: [],
                            black: []
                        }
                        this.data.countries = {
                            white: [],
                            black: []
                        }

                        for (let item of merchants.white) {
                            this.data.merchants.white.push(this.utils.merchants.find(row => row.id == item))
                        }
                        for (let item of merchants.black) {
                            this.data.merchants.black.push(this.utils.merchants.find(row => row.id == item))
                        }

                        for (let item of countries.white) {
                            this.data.countries.white.push(this.utils.countries.find(row => row.key == item))
                        }
                        for (let item of countries.black) {
                            this.data.countries.black.push(this.utils.countries.find(row => row.key == item))
                        }

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errors[response.field] = response.message
                    } else {
                        this.$toast.error(response.message)
                    }
                }

                if(error.response.status == 404) {
                    this.$router.push({name: 'GamesItems'})
                }
            })
        },
        updateItem () {
            if(!this.data.name) {
                this.errors.name = 'Это поле обязательно'
                return
            }
            if(!this.data.slug) {
                this.errors.slug = 'Это поле обязательно'
                return
            }
            if(!this.data.provider) {
                this.errors.provider = 'Это поле обязательно'
                return
            }
            if(!this.data.category) {
                this.errors.category = 'Это поле обязательно'
                return
            }
            if(!this.data.status) {
                this.errors.status = 'Это поле обязательно'
                return
            }

            var merchants = {
                white: [],
                black: []
            }
            var countries = {
                white: [],
                black: []
            }

            for (let item of this.data.merchants.white) {
                merchants.white.push(item.id)
            }
            for (let item of this.data.merchants.black) {
                merchants.black.push(item.id)
            }

            for (let item of this.data.countries.white) {
                countries.white.push(item.key)
            }
            for (let item of this.data.countries.black) {
                countries.black.push(item.key)
            }
            
            this.btn_preloader = true
            this.$axios.post('/games/update', JSON.stringify({
                id: this.$props.id,
                name: this.data.name,
                provider: this.data.provider.id,
                category: this.data.category.id,
                bonus_buy: this.data.bonus_buy.key,
                is_megaways: this.data.is_megaways.key,
                jackpot: this.data.jackpot.key,
                is_allowed_to_wager: this.data.is_allowed_to_wager.key,
                is_holiday: this.data.is_holiday.key,
                merchants: merchants,
                countries: countries,
                status: this.data.status.key
            }))
            .then((response) => {
                this.btn_preloader = false
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":
                        this.$toast.success(response.message)

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data

                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errors[response.field] = response.message
                    } else {
                        this.$toast.error(response.message)
                    }
                }
            })
        },
        async getProviders () {
            await this.$axios.post('/games/providers/items', JSON.stringify({
                search: {
                    name: null,
                    status: null
                }
            }))
            .then((response) => {
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':
                        for (let item of response.data) {
                            this.utils.providers.push({
                                name: item.name,
                                id: item.id
                            })
                        }

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })     
        },
        async getCategories () {
            await this.$axios.post('/games/categories/items', JSON.stringify({
                search: {
                    name: null,
                    status: null
                }
            }))
            .then((response) => {
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':
                        for (let item of response.data) {
                            this.utils.categories.push({
                                name: item.name,
                                id: item.id
                            })
                        }

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)
                
                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })     
        },
        async getMerchants () {
            await this.$axios.post('/merchants/items')
            .then((response) => {
                this.preloader = false
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':
                        for (let item of response.data) {
                            this.utils.merchants.push({
                                name: item.name,
                                id: item.id
                            })
                        }

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })     
        },
        async getCountries () {
            await this.$axios.post('/utils/countries/items')
            .then((response) => {
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':

                        for(let item in response.data) {
                            this.utils.countries.push({
                                name: response.data[item],
                                key: item
                            })
                        }

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)
                
                if(response && 'status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })     
        }
    },
    async beforeMount() {
        await this.getProviders()
        await this.getCategories()
        await this.getMerchants()
        await this.getCountries()

        this.getItem()
    }
}
</script>

<style lang="css" scoped>
    ::v-deep .vs__clear {
        display: none;
    }
</style>
