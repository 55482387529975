<template>
    <main class="main">
        <div class="container-fluid px-0" data-layout="container">

            <aside-main/>

            <header-main/>

            <div class="content">

                <div class="row g-3">
                    <div class="col-sm-6 col-md-6">
                        <h2 class="text-bold text-1100 mb-5">
                            KYC верификация
                            <span class="fw-normal text-700 ms-2">({{items_count}})</span>
                        </h2>
                    </div>
                </div>

                <div class="row align-items-center justify-content-between g-3 mb-3">
                    <div class="col-sm-6 col-md-4">
                        <div class="form-icon-container">
                            <vue-select
                                @input="changeSearch"
                                @search="searchUsers"
                                v-model="search.user"
                                :options="utils.users" 
                                placeholder="Пользователь"
                                label="email">
                                <template v-slot:option="option">
                                    <span>{{ option.email }}</span>
                                </template>
                                <template v-slot:no-options="{ search, searching }">
                                    <template v-if="searching && search.length >= 3">
                                        Ничего не найдено по запросу <em>{{ search }}</em>.
                                    </template>
                                    <span v-else>Введите не менее 3 символов</span>
                                </template>
                            </vue-select>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-4">
                        <div class="form-icon-container">
                            <vue-select
                                @input="changeSearch"
                                v-model="search.status"
                                :options="utils.statuses"
                                placeholder="Статус"
                                label="name">
                                <template v-slot:no-options="{ search, searching }">
                                  <template v-if="searching">
                                     Ничего не найдено по запросу <em>{{ search }}</em>.
                                  </template>
                                </template>
                            </vue-select>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-4">
                        <div class="form-icon-container">
                            <date-range-picker
                                @update="changeSearch"
                                :locale-data="datepiker"
                                :opens="pikerparams.opens"
                                :always-show-calendars="pikerparams.showcalendars"
                                :close-on-esc="pikerparams.closeonesc"
                                :auto-apply="pikerparams.autoapply"
                                :time-picker="pikerparams.timePicker"
                                :time-picker24-hour="pikerparams.timePicker24Hour"
                                v-model="search.date"
                                class="daterange-picker-medium">
                                <template #ranges="ranges">
                                  <div class="ranges">
                                    <ul>
                                      <li 
                                        v-for="(range, name, key) in pikerranges" 
                                        :key="name" 
                                        @click="ranges.clickRange(range, key)">
                                            {{name}}
                                      </li>
                                    </ul>
                                  </div>
                                </template>
                            </date-range-picker>
                        </div>
                    </div>
                </div>


                <div class="card-body p-0 spinner-center" v-if="preloader">
                    <div class="p-4 code-to-copy">
                        <div class="spinner-border" role="status"></div>
                    </div>
                </div>


                <div class="mx-n4 mx-lg-n6 px-4 px-lg-6 mb-9 bg-white border-y border-300 mt-2 position-relative top-1" v-show="!preloader">
                    <div class="table-responsive scrollbar ms-n1 ps-1">
                    
                        <div class="table table-responsive-small">
                            <datatable
                                :filter="search.search_change"
                                :columns="columns" 
                                :data="getItems"
                                :per-page="pager.per_page">
                                <template scope="{ row, columns }">
                                    <tr>
                                        <td>{{ row.id }}</td>
                                        <td><router-link :to="{name: 'UsersItem', params: {id: row.user_id}}">{{ row.email }}</router-link></td>
                                        <td>
                                            <template v-if="row.status == 1">
                                                <span class="badge badge-casino fs--3 badge-casino-success">
                                                    {{ utils.statuses.find(el => el.key == row.status).name }}
                                                    <i class="fa-solid fa-check"></i>
                                                </span>
                                            </template>
                                            <template v-else-if="row.status == 0">
                                                <span class="badge badge-casino fs--3 badge-casino-warning">
                                                    {{ utils.statuses.find(el => el.key == row.status).name }}
                                                    <i class="fa-regular fa-clock"></i>
                                                </span>
                                            </template>
                                            <template v-else-if="row.status == 2">
                                                <span class="badge badge-casino fs--3 badge-casino-danger">
                                                    {{ utils.statuses.find(el => el.key == row.status).name }}
                                                    <i class="fa-solid fa-xmark"></i>
                                                </span>

                                                <span 
                                                    v-tippy="{arrow : true, arrowType: 'round'}"
                                                    :content="`Причина:<br>${row.reject_message}`"
                                                    class="fa-regular fa-circle-question ml-2 text-warning" 
                                                    style="vertical-align: middle; cursor: pointer;"></span>
                                            </template>
                                        </td>
                                        <td>{{ $moment(row.created_at).format('YYYY-MM-DD H:mm:ss') }}</td>
                                        <td>
                                            <a
                                                v-if="row.user_kyc == 0"
                                                @click="approveKyc(row.user_id)"
                                                v-tippy="{arrow : true, arrowType: 'round'}"
                                                content="Верифицировать KYC пользователя"
                                                class="btn btn-soft-warning btn-sm me-1 mb-1">
                                                <i class="fa-regular fa-user-check"></i>
                                            </a>
                                        </td>
                                        <td>
                                            <a
                                                v-if="row.status == 0"
                                                @click="confirmDoc(row.id)"
                                                v-tippy="{arrow : true, arrowType: 'round'}"
                                                content="Одобрить"
                                                class="btn btn-soft-success btn-sm me-1 mb-1">
                                                <i class="fa-solid fa-check"></i>
                                            </a>
                                            <a
                                                v-if="row.status == 0"
                                                @click="rejectDoc(row.id)"
                                                v-tippy="{arrow : true, arrowType: 'round'}"
                                                content="Отклонить"
                                                class="btn btn-soft-danger btn-sm me-1 mb-1">
                                                <i class="fa-solid fa-ban"></i>
                                            </a>

                                            <router-link
                                                :to="{name: 'UsersKycItem', params: {id: row.id}}"
                                                content="Редактировать" 
                                                v-tippy="{arrow : true, arrowType: 'round'}"
                                                class="btn btn-soft-primary btn-sm me-1 mb-1">
                                                <i class="fa-regular fa-pen-to-square"></i>
                                            </router-link>
                                        </td>
                                    </tr>
                                </template>
                            </datatable>
                        </div>

                        <div class="col-auto d-flex">
                            <ul class="mb-3 pagination">
                                <datatable-pager v-model="pager.page" type="abbreviated"></datatable-pager>
                            </ul>
                        </div>

                    </div>
                </div>


                <!-- Modals -->

                <modal 
                    name="reject-kyc"
                    height="auto"
                    :shiftY="0.3"
                    :adaptive="true">
                    <form class="row g-3" v-on:submit.prevent="rejectDocConfirm">
                        <div class="col-sm-12 col-md-12">
                            <div class="form-floating">
                                <input
                                    v-model="reject.message"
                                    @input="errors.reject.message = null"
                                    :class="{'is-invalid': errors.reject.message}"
                                    placeholder="Причина отклонения"
                                    class="form-control" 
                                    type="text"/>
                                <label>Причина отклонения</label>
                                <div class="invalid-feedback">{{errors.reject.message}}</div>
                            </div>
                        </div>
                        <div class="col-sm-3 col-md-3">
                            <button class="btn btn-danger px-5 w-100 text-nowrap" type="submit" :class="{'disabled': btn_preloader}">
                                <span class="spinner-border spinner-border-sm spinner-border-custom" role="status" v-if="btn_preloader"></span>
                                <span>Отклонить</span>
                            </button>
                        </div>
                    </form>
                </modal>
                
                <footer-main/>

            </div>

        </div>
    </main>
</template>

<script>
export default {
    props: ['type'],
    data () {
        return {
            btn_preloader: false,
            preloader: true,
            pager: {
                per_page: 25,
                page: 0
            },
            reject: {
                id: null,
                message: null
            },
            items_count: 0,
            search: {
                search_change: null,
                user: null,
                status: null,
                date: {
                    startDate: this.$moment().set({date: this.$moment().get('date') - 30}).format('Y-MM-DD HH:mm:ss'),
                    endDate: this.$moment().format('Y-MM-DD HH:mm:ss')
                }
            },
            columns: [
                {label: 'ID', field: 'id', sortable: true},
                {label: 'Пользователь'},
                {label: 'Статус'},
                {label: 'Дата', field: 'created_at', sortable: true},
            ],
            errors: {
                reject: {
                    message: false
                }
            },
            utils: {
                users: [],
                statuses: [
                    {
                        name: 'Выполнен',
                        key: 1
                    },
                    {
                        name: 'В ожидании',
                        key: 0
                    },
                    {
                        name: 'Отклонен',
                        key: 2
                    },
                ]
            },
            datepiker: {
                direction: 'ltr',
                format: 'yyyy.mm.dd HH:MM',
                separator: ' - ',
                applyLabel: 'Применить',
                cancelLabel: 'Отмена',
                weekLabel: 'W',
                customRangeLabel: 'Настраиваемый диапазон',
                daysOfWeek: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
                monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
                firstDay: 0
            },
            pikerparams: {
                showcalendars: true,
                closeonesc: true,
                autoapply: false,
                timePicker: true,
                timePicker24Hour: true,
                opens: 'left'
            },
            pikerranges: {
                'Один день': [this.$moment().set({date: this.$moment().get('date') - 1}).toDate(), this.$moment().toDate()],
                'Одна неделя': [this.$moment().set({date: this.$moment().get('date') - 7}).toDate(), this.$moment().toDate()],
                'Один месяц': [this.$moment().set({date: this.$moment().get('date') - 30}).toDate(), this.$moment().toDate()],
                'Два месяца': [this.$moment().set({date: this.$moment().get('date') - 60}).toDate(), this.$moment().toDate()],
                'Три месяца': [this.$moment().set({date: this.$moment().get('date') - 90}).toDate(), this.$moment().toDate()],
                'Пол года': [this.$moment().set({date: this.$moment().get('date') - 180}).toDate(), this.$moment().toDate()],
                'Один год': [this.$moment().set({date: this.$moment().get('date') - 365}).toDate(), this.$moment().toDate()]
            }
        }
    },
    methods: {
        async getItems ({ sortBy, sortDir, perPage, page }) {
            this.preloader = true

            var items = await this.$axios.post('/users/kyc/items', JSON.stringify({
                type: this.$props.type,
                pager: {
                    per_page: this.pager.per_page,
                    page: page - 1
                },
                sort: {
                    by: sortBy,
                    dir: sortDir
                },
                search: {
                    user: this.search.user ? this.search.user.id : null,
                    status: this.search.status != null ? this.search.status.key : null,
                    date: {
                        start: this.$moment(this.search.date.startDate).format('YYYY-MM-DD H:mm:ss'),
                        expire: this.$moment(this.search.date.endDate).format('YYYY-MM-DD H:mm:ss')
                    }
                }
            }))
            .then((response) => {
                this.preloader = false
                this.setUser(response.data.user)
                return response.data
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })

            if(items && items.status == 'success') {
                this.items_count = items.data.count
                return {
                    rows: items.data.items,
                    totalRowCount: items.data.count
                }
            }

            return {
                rows: [],
                totalRowCount: 0
            }
        },
        searchUsers (search, loading) {
            if(search.length >= 3) {
                loading(true)
                 this.$axios.post('/users/search/identificator', JSON.stringify({
                    search: search
                 }))
                .then((response) => {
                    response = response.data
                    switch (response.status) {
                        case "error":
                            this.$toast.error(response.message)
                            break
                        case "success":
                            loading(false)
                            this.utils.users = []
                            for (let user of response.data) {
                                this.utils.users.push({
                                    id: user.id,
                                    email: user.email
                                })
                            }
                            this.setUser(response.user)
                            break
                        default:
                            this.$toast.error('Unknown error')
                    }
                }).catch((error) => {
                    let response = error.response.data
                    this.setUser(response.user)

                    if('status' in response && response.status == 'error') {
                        if('field' in response) {
                            this.errors[response.field] = response.message
                        } else {
                            this.$toast.error(response.message)
                        }
                    }
                })
            }
        },
        changeSearch () {
            this.search.search_change = String(Date.now())
        },
        approveKyc (user_id) {
            this.$confirm('Вы уверены?').then(() => {
                this.$axios.post(`/users/kyc/approve/${user_id}`)
                .then((response) => {
                    response = response.data
                    switch (response.status) {
                        case 'error':
                            this.$toast.error(response.message)
                            break
                        case 'success':
                            this.setNotificationKyc(this.$store.state.notifications.kyc - 1)
                            this.changeSearch()
                            this.$toast.success(response.message)

                            this.setUser(response.user)
                            break
                        default:
                            this.$toast.error('Unknown error')
                    }
                }).catch((error) => {
                    let response = error.response.data
                    this.setUser(response.user)

                    if('status' in response && response.status == 'error') {
                        this.$toast.error(response.message)
                    }
                })
            })
        },
        confirmDoc (id) {
            this.$confirm('Вы уверены?').then(() => {
                this.$axios.post('/users/kyc/accept', JSON.stringify({
                    id: id
                }))
                .then((response) => {
                    response = response.data
                    switch (response.status) {
                        case 'error':
                            this.$toast.error(response.message)
                            break
                        case 'success':
                            this.setNotificationKyc(this.$store.state.notifications.kyc - 1)
                            this.changeSearch()
                            this.$toast.success(response.message)

                            this.setUser(response.user)
                            break
                        default:
                            this.$toast.error('Unknown error')
                    }
                }).catch((error) => {
                    let response = error.response.data
                    this.setUser(response.user)

                    if('status' in response && response.status == 'error') {
                        this.$toast.error(response.message)
                    }
                })
            })
        },
        rejectDoc (id) {
            this.$confirm('Вы уверены?').then(() => {
                this.reject.id = id
                this.$modal.show('reject-kyc')
            })
        },
        rejectDocConfirm () {
            if(!this.reject.message) {
                this.errors.reject.message = 'Это поле обязательно'
                return
            }
            this.btn_preloader = true
            this.$axios.post('/users/kyc/reject', JSON.stringify({
                id: this.reject.id,
                reject_message: this.reject.message
            }))
            .then((response) => {
                this.btn_preloader = false
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':
                        this.setNotificationKyc(this.$store.state.notifications.kyc - 1)
                        this.changeSearch()
                        this.$modal.hide('reject-kyc')
                        this.$toast.success(response.message)

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                this.btn_preloader = false
                let response = error.response.data
                this.setUser(response.user)
                
                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errors[response.field] = response.message
                    } else {
                        this.$toast.error(response.message)
                    }
                }
            })
        },
    }
}
</script>
