<template>
    <main class="main">
        <div class="container-fluid px-0" data-layout="container">

            <aside-main/>

            <header-main/>

            <div class="content">

                <div class="card-header mb-5 d-flex flex-between-center">
                    <div class="d-flex align-items-center">
                        <h2 class="text-bold text-1100">
                            Платежные системы
                            <span class="fw-normal text-700 ms-2">({{items_count}})</span>
                        </h2>
                    </div>

                    <div class="d-flex">
                        <router-link
                            :to="{name: 'PaysystemsCreate'}"
                            class="btn btn-primary me-1 mb-1 float_right">
                            Создать
                        </router-link>
                    </div>
                </div>


                <div class="row align-items-center justify-content-between g-3 mb-3">
                    <div class="col-sm-6 col-md-4">
                        <div class="form-icon-container">
                            <input
                                @input="changeSearch"
                                v-model="search.paysys"
                                type="text" 
                                placeholder="Платежная система" 
                                class="form-control form-icon-input">
                            <span class="fa-regular fa-magnifying-glass text-500 fs--1 form-icon"></span>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-4">
                        <div class="form-icon-container">
                            <vue-select
                                @input="changeSearch"
                                v-model="search.type"
                                :options="utils.types_search" 
                                placeholder="Направление"
                                label="name">
                                <template v-slot:no-options="{ search, searching }">
                                  <template v-if="searching">
                                     Ничего не найдено по запросу <em>{{ search }}</em>.
                                  </template>
                                </template>
                            </vue-select>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-4">
                        <div class="form-icon-container">
                            <vue-select
                                @input="changeSearch"
                                v-model="search.merchant"
                                :options="utils.merchants" 
                                placeholder="Мерчант"
                                label="name">
                                <template v-slot:no-options="{ search, searching }">
                                  <template v-if="searching">
                                     Ничего не найдено по запросу <em>{{ search }}</em>.
                                  </template>
                                </template>
                            </vue-select>
                        </div>
                    </div>
                </div>

                <div class="card-body p-0 spinner-center" v-if="preloader">
                    <div class="p-4 code-to-copy">
                        <div class="spinner-border" role="status"></div>
                    </div>
                </div>
                

                <div class="mx-n4 mx-lg-n6 px-4 px-lg-6 mb-9 bg-white border-y border-300 mt-2 position-relative top-1" v-show="!preloader">
                    <div class="table-responsive scrollbar ms-n1 ps-1" style="padding-bottom: 5px">
                        <div class="table table-responsive-small">
                            <datatable
                                :filter="search.search_change"
                                :columns="columns" 
                                :data="getItems"
                                :per-page="pager.per_page">
                                <template scope="{ row, columns }">
                                    <tr>
                                        <td>{{ row.name }}</td>
                                        <td>{{ utils.statuses.find(el => el.key == row.status).name }}</td>
                                        <td>{{ utils.types[row.type] }}</td>
                                        <td>{{ getMerchantsNames(row.merchants) }}</td>
                                        <td>
                                            <button
                                                @click="changeStatus(row.id)"
                                                :content="row.status == 1 ? 'Отключить' : 'Включить'" 
                                                v-tippy="{arrow : true, arrowType: 'round'}"
                                                :class="{'disabled': status_item_preloader}"
                                                class="btn btn-soft-secondary btn-sm me-1 mb-1">
                                                <span class="spinner-border spinner-border-sm spinner-border-custom-small" role="status" v-if="status_item_preloader && status_item_preloader == row.id"></span>
                                                <i
                                                    v-if="!status_item_preloader || status_item_preloader != row.id"
                                                    :class="{'fa-circle-pause': row.status == 1, 'fa-play': row.status == 0}"
                                                    class="fa-regular fa-circle-pause"></i>
                                            </button>
                                            <router-link
                                                :to="{name: 'PaysystemsItem', params: {id: row.id}}"
                                                content="Редактировать" 
                                                v-tippy="{arrow : true, arrowType: 'round'}"
                                                class="btn btn-soft-primary btn-sm me-1 mb-1">
                                                <i class="fa-regular fa-pen-to-square"></i>
                                            </router-link>
                                        </td>
                                    </tr>
                                </template>

                                <template name="no-result">
                                    Ничего не найдено
                                </template>
                            </datatable>
                        </div>
                    </div>
                </div>
                
                <footer-main/>

            </div>

        </div>
    </main>
</template>

<script>
export default {
    data () {
        return {
            preloader: true,
            status_item_preloader: false,
            pager: {
                per_page: 25,
                page: 0
            },
            items_count: 0,
            search: {
                search_change: null,
                paysys: null,
                type: null,
                merchant: null
            },
            columns: [
                {label: 'Название'},
                {label: 'Статус'},
                {label: 'Направление'},
                {label: 'Мерчанты'},
            ],
            utils: {
                merchants: [],
                types: {
                    deposit: 'Депозит',
                    withdrawal: 'Вывод'
                },
                types_search: [
                    {
                        name: 'Депозит',
                        key: 'deposit'
                    },
                    {
                        name: 'Вывод',
                        key: 'withdrawal'
                    }
                ],
                statuses: [
                    {
                        name: 'Включена',
                        key: 1
                    },
                    {
                        name: 'Отключена',
                        key: 0
                    }
                ]
            }
        }
    },
    methods: {
        async getItems ({ sortBy, sortDir, perPage, page }) {
            this.preloader = true

            if(!this.utils.merchants.length) {
                await this.getMerchants()
            }

            var items = await this.$axios.post('/paysystems/items', JSON.stringify({
                pager: {
                    per_page: this.pager.per_page,
                    page: page - 1
                },
                sort: {
                    by: sortBy,
                    dir: sortDir
                },
                search: {
                    paysys: this.search.paysys ? this.search.paysys : null,
                    type: this.search.type != null ? this.search.type.key : null,
                    merchant: this.search.merchant != null ? this.search.merchant.id : null
                }
            }))
            .then((response) => {
                this.preloader = false
                this.setUser(response.data.user)
                return response.data
            }).catch((error) => {
                this.preloader = false
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })

            if(items && items.status == 'success') {
                this.items_count = items.data.count
                return {
                    rows: items.data.items,
                    totalRowCount: items.data.count
                }
            }

            return {
                rows: [],
                totalRowCount: 0
            }
        },
        /*getItems () {
            this.$axios.post('/paysystems/items')
            .then(async (response) => {
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':
                        await this.getMerchants()
                        this.items = response.data

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errors[response.field] = response.message
                    } else {
                        this.$toast.error(response.message)
                    }
                }
            })     
        },*/
        async getMerchants () {
            await this.$axios.post('/merchants/items')
            .then((response) => {
                this.preloader = false
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':
                        for (let item of response.data) {
                            this.utils.merchants.push({
                                name: item.name,
                                currency: item.currency,
                                id: item.id
                            })
                        }

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })     
        },
        changeStatus (id) {
            this.status_item_preloader = id
            this.$axios.post(`/paysystems/changestatus/${id}`)
            .then((response) => {
                this.status_item_preloader = false
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':

                        this.changeSearch()
                        //let item_index = this.items.findIndex(row => row.id == id)
                        //this.items[item_index].status = this.items[item_index].status == 1 ? 0 : 1

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)
                
                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })     
        },
        getMerchantsNames (merchants) {
            var merchants_name = []
            for (var merchant of merchants) {
                merchants_name.push(this.utils.merchants.find(row => row.id == merchant).currency)
            }
            return merchants_name.join(', ')
        },
        changeSearch () {
            this.search.search_change = String(Date.now())
        },
    }
}
</script>
