<template>
    <main class="main">
        <div class="container-fluid px-0" data-layout="container">

            <aside-main/>

            <header-main/>

            <div class="content">
                <form class="mb-9" v-on:submit.prevent="updateItem">
                    <div class="row justify-content-between align-items-end g-3 mb-5">
                        <div class="col-12 col-sm-auto col-xl-8">
                            <h2>Шаблон {{ data.name }}</h2>
                        </div>
                        <div class="col-12 col-sm-auto col-xl-4">
                            <div class="d-flex">
                                <button
                                    :class="{'disabled': btn_preloader}"
                                    class="btn btn-primary px-5 w-100 text-nowrap" 
                                    type="submit">
                                    <span class="spinner-border spinner-border-sm spinner-border-custom" role="status" v-if="btn_preloader"></span>
                                    <span>Сохранить</span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="card-body p-0 spinner-center" v-if="preloader">
                        <div class="p-4 code-to-copy">
                            <div class="spinner-border" role="status"></div>
                        </div>
                    </div>

                    <div class="row g-5" v-if="!preloader">
                        <div class="col-xl-12">

                            <h4 class="fs-1 mb-4">Основная информация</h4>

                            <div class="row g-3">
                                <div class="col-sm-6 col-md-12">
                                    <div class="form-floating">
                                        <input
                                            v-model="data.name"
                                            @input="errors.name = null"
                                            :class="{'is-invalid': errors.name}"
                                            placeholder="Название"
                                            class="form-control" 
                                            type="text"/>
                                        <label>Название</label>
                                        <div class="invalid-feedback">{{errors.name}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <input
                                            v-model="data.deposit_index"
                                            @input="errors.deposit_index = null"
                                            :class="{'is-invalid': errors.deposit_index}"
                                            placeholder="№ Депозита"
                                            class="form-control" 
                                            type="text"/>
                                        <label>№ Депозита</label>
                                        <div class="invalid-feedback">{{errors.deposit_index}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <input
                                            v-model="data.expire_in_days"
                                            @input="errors.expire_in_days = null"
                                            :class="{'is-invalid': errors.expire_in_days}"
                                            placeholder="Истекает через N Дней"
                                            class="form-control" 
                                            type="text"/>
                                        <label>Истекает через N Дней</label>
                                        <div class="invalid-feedback">{{errors.expire_in_days}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating v-select-medium" :class="{'v-select-error': errors.method}">
                                        <vue-select
                                            @input="errors.method = null"
                                            v-model="data.method"
                                            :options="utils.methods" 
                                            placeholder="Method"
                                            label="name">
                                            <template v-slot:no-options="{ search, searching }">
                                              <template v-if="searching">
                                                 Ничего не найдено по запросу <em>{{ search }}</em>.
                                              </template>
                                            </template>
                                        </vue-select>
                                        <label>Method</label>
                                        <div class="invalid-feedback">{{errors.method}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <input
                                            v-model="data.wager"
                                            @input="errors.wager = null"
                                            :class="{'is-invalid': errors.wager}"
                                            placeholder="Wager"
                                            class="form-control" 
                                            type="text"/>
                                        <label>Wager</label>
                                        <div class="invalid-feedback">{{errors.wager}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating v-select-medium" :class="{'v-select-error': errors.type}">
                                        <vue-select
                                            disabled
                                            @input="errors.type = null"
                                            v-model="data.type"
                                            :options="utils.types" 
                                            placeholder="Тип"
                                            label="name">
                                            <template v-slot:no-options="{ search, searching }">
                                              <template v-if="searching">
                                                 Ничего не найдено по запросу <em>{{ search }}</em>.
                                              </template>
                                            </template>
                                        </vue-select>
                                        <label>Тип</label>
                                        <div class="invalid-feedback">{{errors.type}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating v-select-medium" :class="{'v-select-error': errors.status}">
                                        <vue-select
                                            @input="errors.status = null"
                                            v-model="data.status"
                                            :options="utils.statuses" 
                                            placeholder="Статус"
                                            label="name">
                                            <template v-slot:no-options="{ search, searching }">
                                              <template v-if="searching">
                                                 Ничего не найдено по запросу <em>{{ search }}</em>.
                                              </template>
                                            </template>
                                        </vue-select>
                                        <label>Статус</label>
                                        <div class="invalid-feedback">{{errors.status}}</div>
                                    </div>
                                </div>


                                <h4 class="fs-1 mt-5">Настройка шаблона</h4>

                                <template v-if="data.type.key == 1 && refresh_merchant_data">

                                    <template v-for="(item, key) in utils.merchants">
                                        <div class="col-sm-6 col-md-4">
                                            <div class="form-floating v-select-medium">
                                                <vue-select
                                                    disabled
                                                    v-model="item.name"
                                                    label="name">
                                                    <template v-slot:no-options="{ search, searching }">
                                                      <template v-if="searching">
                                                         Ничего не найдено по запросу <em>{{ search }}</em>.
                                                      </template>
                                                    </template>
                                                </vue-select>
                                                <label>Мерчант</label>
                                            </div>
                                        </div>

                                        <div class="col-sm-6 col-md-4">
                                            <div class="form-floating">
                                                <input
                                                    v-model="data.merchants[item.id].min_deposit"
                                                    @input="errors.merchants[item.id].min_deposit = null, refreshMerchantData()"
                                                    :class="{'is-invalid': errors.merchants[item.id].min_deposit}"
                                                    placeholder="Мин. сумма депозита"
                                                    class="form-control" 
                                                    type="text"/>
                                                <label>Мин. сумма депозита</label>
                                                <div class="invalid-feedback">{{ errors.merchants[item.id].min_deposit }}</div>
                                            </div>
                                        </div>
                                        
                                        <div class="col-sm-6 col-md-4">
                                            <div class="form-floating">
                                                <input
                                                    v-model="data.merchants[item.id].amount"
                                                    @input="errors.merchants[item.id].amount = null, refreshMerchantData()"
                                                    :class="{'is-invalid': errors.merchants[item.id].amount}"
                                                    placeholder="Сумма начисления"
                                                    class="form-control" 
                                                    type="text"/>
                                                <label>Сумма начисления</label>
                                                <div class="invalid-feedback">{{ errors.merchants[item.id].amount }}</div>
                                            </div>
                                        </div>
                                    </template>
                                </template>

                                <template v-else-if="data.type.key == 2 && refresh_merchant_data">

                                    <div class="col-sm-6 col-md-6">
                                        <div class="form-floating v-select-medium" :class="{'v-select-error': errors.game}">
                                            <vue-select
                                                @input="errors.game = null"
                                                @search="searchGames"
                                                v-model="data.game"
                                                :options="utils.games" 
                                                placeholder="Игра"
                                                label="name">
                                                <template v-slot:option="option">
                                                    <img style="width: 50px;margin-right: 20px;height: 100%; height: 35px;" :src="option.image"/>
                                                    <span>{{ option.name }}</span>
                                                    <template v-if="option.is_mobile">
                                                        <span class="badge badge-casino fs--3 badge-casino-warning" style="float: right;margin-top: 10px;margin-left: 10px;">Mobile</span>
                                                    </template>
                                                    <template v-if="option.is_desktop">
                                                        <span class="badge badge-casino fs--3 badge-casino-success" style="float: right;margin-top: 10px;margin-left: 10px;">Desktop</span>
                                                    </template>
                                                </template>
                                                <template v-slot:no-options="{ search, searching }">
                                                    <template v-if="searching && search.length >= 3">
                                                        Ничего не найдено по запросу <em>{{ search }}</em>.
                                                    </template>
                                                    <span v-else>Введите не менее 3 символов</span>
                                                </template>
                                            </vue-select>
                                            <label>Игра</label>
                                            <div class="invalid-feedback">{{errors.game}}</div>
                                        </div>
                                    </div>

                                    <div class="col-sm-6 col-md-6">
                                        <div class="form-floating">
                                            <input
                                                v-model="data.freespins_number"
                                                @input="errors.freespins_number = null"
                                                :class="{'is-invalid': errors.freespins_number}"
                                                placeholder="Количество фриспинов"
                                                class="form-control" 
                                                type="text"/>
                                            <label>Количество фриспинов</label>
                                            <div class="invalid-feedback">{{errors.freespins_number}}</div>
                                        </div>
                                    </div>

                                    <template v-for="(item, key) in utils.merchants" v-if="data.game && data.freespins_number > 0 && refresh_merchant_data">
                                        <div class="col-sm-6 col-md-4">
                                            <div class="form-floating v-select-medium">
                                                <vue-select
                                                    disabled
                                                    v-model="item.name"
                                                    label="name">
                                                    <template v-slot:no-options="{ search, searching }">
                                                      <template v-if="searching">
                                                         Ничего не найдено по запросу <em>{{ search }}</em>.
                                                      </template>
                                                    </template>
                                                </vue-select>
                                                <label>Мерчант</label>
                                            </div>
                                        </div>

                                        <div class="col-sm-6 col-md-4">
                                            <div class="form-floating">
                                                <input
                                                    v-model="data.merchants[item.id].min_deposit"
                                                    @input="errors.merchants[item.id].min_deposit = null, refreshMerchantData()"
                                                    :class="{'is-invalid': errors.merchants[item.id].min_deposit}"
                                                    placeholder="Мин. сумма депозита"
                                                    class="form-control" 
                                                    type="text"/>
                                                <label>Мин. сумма депозита</label>
                                                <div class="invalid-feedback">{{ errors.merchants[item.id].min_deposit }}</div>
                                            </div>
                                        </div>

                                        <div class="col-sm-6 col-md-4">
                                            <div class="form-floating v-select-medium" :class="{'v-select-error': errors.merchants[item.id].bet}">
                                                <vue-select
                                                    @open="openBetMerchant(item)"
                                                    @input="errors.merchants[item.id].bet = null, refreshMerchantData()"
                                                    v-model="data.merchants[item.id].bet"
                                                    :options="utils.freespins_bets[item.id]" 
                                                    placeholder="Сумма бета"
                                                    label="total_bet_per_line">
                                                    <template v-slot:option="option">
                                                        <span>{{ option.total_bet_per_line }}</span>
                                                    </template>
                                                    <template v-slot:no-options="{ search, searching }">
                                                      <template v-if="searching">
                                                         Ничего не найдено по запросу <em>{{ search }}</em>.
                                                      </template>
                                                      <template v-else>
                                                         Идет поиск бетов под мерчант <em>{{item.name}}</em>.
                                                      </template>
                                                    </template>
                                                </vue-select>
                                                <label>Сумма бета</label>
                                                <div class="invalid-feedback">{{ errors.merchants[item.id].bet }}</div>
                                            </div>
                                        </div>
                                    </template>
                                </template>

                                <template v-if="data.type.key == 3 && refresh_merchant_data">

                                    <div class="col-sm-6 col-md-12">
                                        <div class="form-floating">
                                            <input
                                                v-model="data.percent"
                                                @input="errors.percent = null"
                                                :class="{'is-invalid': errors.percent}"
                                                placeholder="Процент"
                                                class="form-control" 
                                                type="text"/>
                                            <label>Процент</label>
                                            <div class="invalid-feedback">{{errors.percent}}</div>
                                        </div>
                                    </div>

                                    <template v-for="(item, key) in utils.merchants">
                                        <div class="col-sm-6 col-md-4">
                                            <div class="form-floating v-select-medium">
                                                <vue-select
                                                    disabled
                                                    v-model="item.name"
                                                    label="name">
                                                    <template v-slot:no-options="{ search, searching }">
                                                      <template v-if="searching">
                                                         Ничего не найдено по запросу <em>{{ search }}</em>.
                                                      </template>
                                                    </template>
                                                </vue-select>
                                                <label>Мерчант</label>
                                            </div>
                                        </div>

                                        <div class="col-sm-6 col-md-4">
                                            <div class="form-floating">
                                                <input
                                                    v-model="data.merchants[item.id].min_deposit"
                                                    @input="errors.merchants[item.id].min_deposit = null, refreshMerchantData()"
                                                    :class="{'is-invalid': errors.merchants[item.id].min_deposit}"
                                                    placeholder="Мин. сумма депозита"
                                                    class="form-control" 
                                                    type="text"/>
                                                <label>Мин. сумма депозита</label>
                                                <div class="invalid-feedback">{{ errors.merchants[item.id].min_deposit }}</div>
                                            </div>
                                        </div>
                                        
                                        <div class="col-sm-6 col-md-4">
                                            <div class="form-floating">
                                                <input
                                                    v-model="data.merchants[item.id].max_deposit"
                                                    @input="errors.merchants[item.id].max_deposit = null, refreshMerchantData()"
                                                    :class="{'is-invalid': errors.merchants[item.id].max_deposit}"
                                                    placeholder="Макс. сумма депозита"
                                                    class="form-control" 
                                                    type="text"/>
                                                <label>Макс. сумма депозита</label>
                                                <div class="invalid-feedback">{{ errors.merchants[item.id].max_deposit }}</div>
                                            </div>
                                        </div>
                                    </template>
                                </template>

                            </div>
                        </div>
                    </div>
                </form>
                
                <footer-main/>

            </div>

        </div>
    </main>
</template>

<script>
export default {
    props: ['id'],
    data () {
        return {
            preloader: true,
            btn_preloader: false,
            refresh_merchant_data: null,
            item: null,
            data: {
                name: null,
                method: null,
                wager: null,
                deposit_index: null,
                expire_in_days: null,
                type: null,
                status: null,
                merchants: {},
                game: null,
                freespins_number: null,
                percent: null
            },
            errors: {
                name: false,
                method: false,
                wager: false,
                deposit_index: false,
                expire_in_days: false,
                type: false,
                status: false,
                merchants: {},
                game: false,
                freespins_number: false,
                percent: false
            },
            utils: {
                merchants: [],
                games: [],
                freespins_bets: [],
                types: [
                    /*{
                        name: 'Ваучер',
                        key: 1
                    },*/
                    /*{
                        name: 'Бесплатные вращения',
                        key: 2
                    },*/
                    {
                        name: 'Кэш Бонус',
                        key: 3
                    }
                ],
                statuses: [
                    {
                        name: 'Включен',
                        key: 1
                    },
                    {
                        name: 'Отключен',
                        key: 0
                    }
                ],
                methods: [
                    {
                        name: 'Бонусный баланс',
                        key: 0
                    },
                    {
                        name: 'Реальный баланс',
                        key: 1
                    }
                ]
            }
        }
    },
    methods: {
        getItem () {
            this.$axios.post(`/promotions/templates/item/${this.$props.id}`)
            .then((response) => {
                this.preloader = false
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':

                        this.item = response.data

                        this.data.name = response.data.name
                        this.data.method = this.utils.methods.find(row => row.key == response.data.method)
                        this.data.wager = response.data.wager
                        this.data.deposit_index = response.data.deposit_index
                        this.data.expire_in_days = response.data.expire_in_days
                        this.data.type = this.utils.types.find(row => row.key == response.data.type)
                        this.data.status = this.utils.statuses.find(row => row.key == response.data.status)
                        this.data.merchants = response.data.merchants
                        this.data.freespins_number = response.data.freespins_number
                        this.data.percent = response.data.percent


                        for (let item of this.utils.merchants) {
                            if(response.data.merchants[item.id]) {
                                this.data.merchants[item.id] = response.data.merchants[item.id]
                            } else {
                                if(response.data.type == 1) {
                                    this.data.merchants[item.id] = {
                                        min_deposit: null,
                                        amount: null
                                    }
                                } else if(response.data.type == 2) {
                                    this.data.merchants[item.id] = null
                                } else if(response.data.type == 3) {
                                    this.data.merchants[item.id] = {
                                        min_deposit: null,
                                        max_deposit: null
                                    }
                                }
                            }
                        }

                        this.refreshMerchantData()

                        if(this.data.type.key == 2) {
                            this.getGame([response.data.game_id])
                        }

                        this.changeType()

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errors[response.field] = response.message
                    } else {
                        this.$toast.error(response.message)
                    }
                }
            })     
        },
        updateItem () {
            if(!this.data.name) {
                this.errors.name = 'Это поле обязательно'
                return
            }
            if(!this.data.method) {
                this.errors.method = 'Это поле обязательно'
                return
            }
            if(!this.data.wager) {
                this.errors.wager = 'Это поле обязательно'
                return
            }
            if(!this.data.deposit_index) {
                this.errors.deposit_index = 'Это поле обязательно'
                return
            }
            if(!this.data.type) {
                this.errors.type = 'Это поле обязательно'
                return
            }
            if(!this.data.expire_in_days) {
                this.errors.expire_in_days = 'Это поле обязательно'
                return
            }
            if(!this.data.status) {
                this.errors.status = 'Это поле обязательно'
                return
            }

            if(this.data.type.key == 1) {

                for (let index in this.data.merchants) {
                    if(!this.data.merchants[index].min_deposit) {
                        this.errors.merchants[index].min_deposit = 'Это поле обязательно'     
                        this.refreshMerchantData()                   
                        return
                    }

                    if(!this.data.merchants[index].amount) {
                        this.errors.merchants[index].amount = 'Это поле обязательно'
                        this.refreshMerchantData()   
                        return
                    }
                }

            } else if(this.data.type.key == 2) {

                if(!this.data.game) {
                    this.errors.game = 'Это поле обязательно'
                    return
                }

                if(!this.data.freespins_number) {
                    this.errors.freespins_number = 'Это поле обязательно'
                    return
                }

                for (let index in this.data.merchants) {
                    if(!this.data.merchants[index].min_deposit) { 
                        this.errors.merchants[index].min_deposit = 'Это поле обязательно'
                        this.refreshMerchantData()
                        return
                    }

                    if(!this.data.merchants[index].bet) { 
                        this.errors.merchants[index].bet = 'Это поле обязательно'
                        this.refreshMerchantData()
                        return
                    }
                }

            } else if(this.data.type.key == 3) {

                if(!this.data.percent) {
                    this.errors.percent = 'Это поле обязательно'
                    return
                }

                for (let index in this.data.merchants) {
                    if(!this.data.merchants[index].min_deposit) {
                        this.errors.merchants[index].min_deposit = 'Это поле обязательно'
                        this.refreshMerchantData()   
                        return
                    }

                    if(!this.data.merchants[index].max_deposit) {
                        this.errors.merchants[index].max_deposit = 'Это поле обязательно'
                        this.refreshMerchantData()  
                        return
                    }
                }

            }

            var data = {
                id: this.$props.id,
                name: this.data.name,
                deposit_index: this.data.deposit_index,
                expire_in_days: this.data.expire_in_days,
                method: this.data.method.key,
                wager: this.data.wager,
                status: this.data.status.key,
                merchants: this.data.merchants
            }
            var type = null

            if(this.data.type.key == 1) {
                type = 'vaucher'
            } else if(this.data.type.key == 2) {
                type = 'freespin'
                data.game = this.data.game.id
                data.freespins_number = this.data.freespins_number
            } else if(this.data.type.key == 3) {
                type = 'percent'
                data.percent = this.data.percent
            }
            
            this.btn_preloader = true
            this.$axios.post(`/promotions/templates/update/${type}`, JSON.stringify(data))
            .then((response) => {
                this.btn_preloader = false
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":
                        this.$toast.success(response.message)

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                this.btn_preloader = false
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errorByPatch(this.errors, response.field, response.message)
                        this.refreshMerchantData()
                    } else {
                        this.$toast.error(response.message)
                    }
                }
            })
        },
        searchGames (search, loading) {
            this.utils.games = []
            if(search.length >= 3) {
                loading(true)
                 this.$axios.post('/games/search/identificator', JSON.stringify({
                    search: search,
                    has_freespins: 1
                 }))
                .then((response) => {
                    response = response.data
                    switch (response.status) {
                        case "error":
                            this.$toast.error(response.message)
                            break
                        case "success":
                            loading(false)
                            for (let game of response.data) {
                                this.utils.games.push({
                                    id: game.id,
                                    uuid: game.uuid,
                                    name: game.name,
                                    image: game.image,
                                    is_mobile: game.is_mobile,
                                    is_desktop: game.is_desktop
                                })
                            }

                            this.setUser(response.user)
                            break
                        default:
                            this.$toast.error('Unknown error')
                    }
                }).catch((error) => {
                    let response = error.response.data
                    this.setUser(response.user)

                    if('status' in response && response.status == 'error') {
                        this.$toast.error(response.message)
                    }
                })
            }
        },
        async getMerchants () {
            await this.$axios.post(`/merchants/items`)
            .then((response) => {
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":
                        this.utils.merchants = response.data
                        this.refreshMerchantData()

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errors[response.field] = response.message
                    } else {
                        this.$toast.error(response.message)
                    }
                }
            })
        },
        getGame (ids) {
             this.$axios.post('/games/search/ids', JSON.stringify({
                games: ids
             }))
            .then((response) => {
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":
                        this.data.game = response.data[0]

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })
        },
        openBetMerchant (merchant) {
            this.$axios.post(`/promotions/freespins/bets`, JSON.stringify({
                merchant: {
                    id: merchant.id,
                    currency: merchant.currency
                },
                game: {
                    id: this.data.game.id,
                    uuid: this.data.game.uuid
                }
            }))
            .then((response) => {
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":

                        for(let item in response.data) {
                            response.data[item].total_bet_per_line = response.data[item].total_bet * response.data[item].denomination
                        }

                        this.utils.freespins_bets[merchant.id] = response.data
                        this.refreshMerchantData()   

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errors[response.field] = response.message
                    } else {
                        this.$toast.error(response.message)
                    }
                }
            })
        },
        changeType () {
            this.data.merchants = {}
            this.utils.freespins_bets = []
            this.errors.merchants = {}

            if(this.data.type.key == 1) {
                for (let item of this.utils.merchants) {
                    this.data.merchants[item.id] = {
                        min_deposit: null,
                        amount: null
                    }

                    this.errors.merchants[item.id] = {
                        min_deposit: false,
                        amount: false
                    }
                }

                if(this.item.type == 1) {
                    this.data.merchants = this.item.merchants
                }
            } else if(this.data.type.key == 2) {
                for (let item of this.utils.merchants) {
                    this.data.merchants[item.id] = {
                        min_deposit: null,
                        bet: null
                    }

                    this.utils.freespins_bets[item.id] = []

                    this.errors.merchants[item.id] = {
                        min_deposit: false,
                        bet: false
                    }
                }
                if(this.item.type == 2) {
                    this.data.merchants = this.item.merchants
                }
            } else if(this.data.type.key == 3) {
                for (let item of this.utils.merchants) {
                    this.data.merchants[item.id] = {
                        min_deposit: null,
                        max_deposit: null
                    }

                    this.errors.merchants[item.id] = {
                        min_deposit: false,
                        max_deposit: false
                    }
                }

                if(this.item.type == 3) {
                    this.data.merchants = this.item.merchants
                }
            }
        },
        errorByPatch (layer, path, value) {
            path = path.split('.')

            path.forEach((value, index) => {
                if (!isNaN(value)) {
                    path[index] = parseInt(value)
                }
            })

            for (var i = 0; i < path.length; i++) {
                if (value != null && i + 1 === path.length) {
                    layer[path[i]] = value
                }
                layer = layer[path[i]]
            }
            return layer
        },
        refreshMerchantData () {
            this.refresh_merchant_data = String(Date.now())
        }
    },
    async beforeMount() {
        await this.getMerchants()

        this.getItem()
    }
}
</script>

<style lang="css" scoped>
    ::v-deep .vs__clear {
        display: none;
    }
</style>
