<template>
    <main class="main">
        <div class="container-fluid px-0" data-layout="container">

            <aside-main/>

            <header-main/>

            <div class="content">

                <div class="card-header mb-5 d-flex flex-between-center">
                    <div class="d-flex align-items-center">
                        <h2>Платежная система</h2>
                    </div>

                    <div class="d-flex" v-if="data.type.key == 'withdrawal'">
                        <div class="form-check" style="float:right">
                            <input 
                                v-model="data.manual_withdraw"
                                class="form-check-input" 
                                id="manual_withdraw" 
                                type="checkbox">
                            <label class="form-check-label" for="manual_withdraw">Ручной вывод</label>
                        </div>
                    </div>
                </div>

                <div class="card-body p-0 spinner-center" v-if="preloader">
                    <div class="p-4 code-to-copy">
                        <div class="spinner-border" role="status"></div>
                    </div>
                </div>

                <div class="row g-5" v-if="!preloader">
                    <div class="col-xl-12 mb-5">
                        <form class="row g-3" v-on:submit.prevent="updateItem">
                            <div class="col-sm-6 col-md-12">
                                <div class="form-floating">
                                    <input
                                        v-model="data.name"
                                        @input="errors.name = null"
                                        :class="{'is-invalid': errors.name}"
                                        placeholder="Название"
                                        class="form-control" 
                                        type="text"/>
                                    <label>Название</label>
                                    <div class="invalid-feedback">{{errors.name}}</div>
                                </div>
                            </div>
                            
                            <div class="col-sm-6 col-md-6">
                                <div class="form-floating v-select-medium" :class="{'v-select-error': errors.type}">
                                    <vue-select
                                        @input="errors.type = null, changeType()"
                                        v-model="data.type"
                                        :options="utils.types" 
                                        placeholder="Направление"
                                        disabled
                                        label="name">
                                        <template v-slot:no-options="{ search, searching }">
                                          <template v-if="searching">
                                             Ничего не найдено по запросу <em>{{ search }}</em>.
                                          </template>
                                        </template>
                                    </vue-select>
                                    <label>Направление</label>
                                    <div class="invalid-feedback">{{errors.type}}</div>
                                </div>
                            </div>

                            <div class="col-sm-6 col-md-6">
                                <div class="form-floating v-select-medium" :class="{'v-select-error': errors.merchants}">
                                    <vue-select
                                        @input="errors.merchants = null"
                                        v-model="data.merchants"
                                        :options="utils.merchants" 
                                        multiple
                                        placeholder="Мерчант"
                                        label="name">
                                        <template v-slot:no-options="{ search, searching }">
                                          <template v-if="searching">
                                             Ничего не найдено по запросу <em>{{ search }}</em>.
                                          </template>
                                        </template>
                                    </vue-select>
                                    <label>Мерчант</label>
                                    <div class="invalid-feedback">{{errors.merchants}}</div>
                                </div>
                            </div>

                            <div class="col-sm-6 col-md-6">
                                <div class="form-floating">
                                    <input
                                        v-model="data.controller"
                                        @input="errors.controller = null"
                                        :class="{'is-invalid': errors.controller}"
                                        placeholder="Controller"
                                        class="form-control" 
                                        type="text"/>
                                    <label>Controller</label>
                                    <div class="invalid-feedback">{{errors.controller}}</div>
                                </div>
                            </div>

                            <div class="col-sm-6 col-md-6">
                                <div class="form-floating v-select-medium" :class="{'v-select-error': errors.status}">
                                    <vue-select
                                        @input="errors.status = null"
                                        v-model="data.status"
                                        :options="utils.statuses" 
                                        placeholder="Статус"
                                        label="name">
                                        <template v-slot:no-options="{ search, searching }">
                                          <template v-if="searching">
                                             Ничего не найдено по запросу <em>{{ search }}</em>.
                                          </template>
                                        </template>
                                    </vue-select>
                                    <label>Статус</label>
                                    <div class="invalid-feedback">{{errors.status}}</div>
                                </div>
                            </div>


                            <div class="card-header p-2 mt-5 d-flex flex-between-center" style="margin-bottom: -10px;">
                                <div class="d-flex align-items-center">
                                    <h2 class="fs-1">Методы</h2>
                                </div>

                                <div class="d-flex">
                                    <a 
                                        @click="newMethod"
                                        class="btn btn-primary mb-1 float_right">Новый метод</a>
                                </div>
                            </div>
                            
                            <draggable v-model="data.methods" draggable=".handle_method">
                                <div class="row mb-3 handle_method" v-for="(item, index) in data.methods">
                                    
                                    <div class="col-sm-6 col-md-2 draggable_raw">
                                        <div class="handle_method_button">
                                            <span class="vertical-center-icon">
                                                <i class="fa-solid fa-grip"></i>
                                            </span>
                                        </div>
                                        <div class="form-floating">
                                            <input
                                                v-model="data.methods[index].name"
                                                @input="errors.methods[index].name = null"
                                                :class="{'is-invalid': errors.methods[index].name}"
                                                placeholder="Название"
                                                class="form-control" 
                                                type="text"/>
                                            <label>Название</label>
                                            <div class="invalid-feedback">{{errors.methods[index].name}}</div>
                                        </div>
                                    </div>

                                    <div class="col-sm-6 col-md-2">
                                        <div class="form-floating">
                                            <input
                                                v-model="data.methods[index].slug"
                                                @input="errors.methods[index].slug = null"
                                                :class="{'is-invalid': errors.methods[index].slug}"
                                                placeholder="Slug"
                                                class="form-control" 
                                                type="text"/>
                                            <label>Slug</label>
                                            <div class="invalid-feedback">{{errors.methods[index].slug}}</div>
                                        </div>
                                    </div>

                                    <div class="col-sm-6 col-md-1">
                                        <div class="form-floating">
                                            <input
                                                v-model="data.methods[index].percent"
                                                @input="errors.methods[index].percent = null"
                                                :class="{'is-invalid': errors.methods[index].percent}"
                                                placeholder="% платеги"
                                                class="form-control" 
                                                type="text"/>
                                            <label>% платеги</label>
                                            <div class="invalid-feedback">{{errors.methods[index].percent}}</div>
                                        </div>
                                    </div>

                                    <div class="col-sm-6 col-md-2">
                                        <div class="form-floating">
                                            <input
                                                v-model="data.methods[index].min_payment"
                                                @input="errors.methods[index].min_payment = null"
                                                :class="{'is-invalid': errors.methods[index].min_payment}"
                                                placeholder="Мин. платеж"
                                                class="form-control" 
                                                type="text"/>
                                            <label>Мин. платеж</label>
                                            <div class="invalid-feedback">{{errors.methods[index].min_payment}}</div>
                                        </div>
                                    </div>

                                    <div class="col-sm-6 col-md-5">
                                        <div class="form-floating v-select-medium select-both-button-row" :class="{'v-select-error': errors.methods[index].image}">
                                            <div 
                                                @click="$refs.uploadFile.click()"
                                                content="Загрузить новое изображение" 
                                                v-tippy="{arrow : true, arrowType: 'round'}"
                                                class="select-button-left">
                                                <span class="vertical-center-icon">
                                                    <i class="fa-solid fa-plus"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <vue-select
                                                    @input="errors.methods[index].image = null"
                                                    v-model="data.methods[index].image"
                                                    :options="utils.images" 
                                                    placeholder="Изображение">
                                                    <template v-slot:option="option">
                                                        <img style="width: 100px;margin-right: 20px;height: 100%; height: 35px;" :src="`${$config.ApplicationStaticURL}/paysys/${option.label}`"/>
                                                        <a 
                                                            @click="deleteImage(option.label)"
                                                            content="Удалить" 
                                                            v-tippy="{arrow : true, arrowType: 'round'}"
                                                            class="btn btn-sm btn-soft-danger ms-2" 
                                                            style="position: absolute; right: 1rem;">
                                                            <i class="fa-regular fa-trash"></i>
                                                        </a>
                                                    </template>
                                                    <template v-slot:no-options="{ search, searching }">
                                                    <template v-if="searching">
                                                        Ничего не найдено по запросу <em>{{ search }}</em>.
                                                    </template>
                                                    </template>
                                                </vue-select>
                                                <label class="custom-select-label">Изображение</label>
                                            </div>

                                            <button
                                                @click="changeStatus(index)"
                                                :content="data.methods[index].status == 1 ? 'Отключить' : 'Включить'" 
                                                v-tippy="{arrow : true, arrowType: 'round'}"
                                                type="button"
                                                class="btn btn-soft-secondary select-button-right">
                                                <span class="vertical-center-icon">
                                                    <i class="icon-paysystem fa-regular" :class="{'fa-circle-pause': data.methods[index].status == 1, 'fa-play': data.methods[index].status == 0}"></i>
                                                </span>
                                            </button>

                                            <button
                                                :disabled="data.methods.length == 1"
                                                @click="deleteMethod(index)"
                                                content="Удалить" 
                                                v-tippy="{arrow : true, arrowType: 'round'}"
                                                type="button"
                                                class="btn btn-soft-danger select-button-right-2">
                                                <span class="vertical-center-icon">
                                                    <i class="icon-paysystem fa-regular fa-trash"></i>
                                                </span>
                                            </button>
                                        </div>
                                        <div class="invalid-feedback" v-if="errors.methods[index].image">{{errors.methods[index].image}}</div>
                                    </div>
                                </div>
                            </draggable>

                            <input ref="uploadFile" type="file" @input="uploadImage" accept=".png, .jpg, .jpeg, .gif, .svg" style="display: none;">

                            <div class="col-sm-3 col-md-3">
                                <button class="btn btn-primary px-5 w-100 text-nowrap" type="submit" :class="{'disabled': btn_preloader}">
                                    <span class="spinner-border spinner-border-sm spinner-border-custom" role="status" v-if="btn_preloader"></span>
                                    <span>Сохранить</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                
                <footer-main/>

            </div>

        </div>
    </main>
</template>

<script>
import draggable from 'vuedraggable'
export default {
    components: {
        draggable,
    },
    props: ['id'],
    data () {
        return {
            preloader: true,
            btn_preloader: false,
            data: {
                name: null,
                type: {
                    name: 'Депозит',
                    key: 'deposit'
                },
                manual_withdraw: 0,
                merchants: null,
                controller: null,
                methods: [{
                    name: null,
                    slug: null,
                    percent: null,
                    min_payment: null,
                    image: null
                }],
                status: {
                    name: 'Отключена',
                    key: 0
                }
            },
            errors: {
                name: false,
                type: false,
                manual_withdraw: false,
                merchants: false,
                controller: false,
                methods: [
                    {
                        name: false,
                        slug: false,
                        percent: false,
                        min_payment: false,
                        image: false
                    }
                ],
                status: false
            },
            utils: {
                merchants: [],
                types: [
                    {
                        name: 'Депозит',
                        key: 'deposit'
                    },
                    {
                        name: 'Вывод',
                        key: 'withdrawal'
                    }
                ],
                statuses: [
                    {
                        name: 'Включена',
                        key: 1
                    },
                    {
                        name: 'Отключена',
                        key: 0
                    }
                ],
                images: []
            }
        }
    },
    methods: {
        getItem () {
            this.$axios.post('/paysystems/item/' + this.$props.id)
            .then(async (response) => {
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':
                        await this.getMerchants()

                        this.data = response.data

                        for (var i = this.data.methods.length - 1; i > 0; i--) {
                            this.errors.methods.push({
                                name: false,
                                slug: false,
                                percent: false,
                                min_payment: false,
                                image: false
                            })
                        }

                        this.data.type = this.utils.types.find(row => row.key == this.data.type)
                        this.data.status = this.utils.statuses.find(row => row.key == this.data.status)

                        var merchants = []

                        for (var merchant of this.data.merchants) {
                            let item = this.utils.merchants.find(row => row.id == merchant)
                            merchants.push(item)
                        }

                        this.data.merchants = merchants

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })     
        },
        updateItem () {
            if(!this.data.name) {
                this.errors.name = 'Это поле обязательно'
                return
            }
            if(!this.data.merchants) {
                this.errors.merchants = 'Это поле обязательно'
                return
            }
            if(!this.data.controller) {
                this.errors.controller = 'Это поле обязательно'
                return
            }
            if(!this.data.status) {
                this.errors.status = 'Это поле обязательно'
                return
            }
            if(!this.data.methods) {
                this.errors.methods = 'Это поле обязательно'
                return
            }

            var th = this, checked = false
            this.data.methods.forEach((item, index) => {
                if(!item.name) {
                    th.errors.methods[index].name = 'Это поле обязательно'
                    checked = true
                    return
                }
                if(!item.slug) {
                    th.errors.methods[index].slug = 'Это поле обязательно'
                    checked = true
                    return
                }
                if(!item.percent) {
                    th.errors.methods[index].percent = 'Это поле обязательно'
                    checked = true
                    return
                }
                if(!item.min_payment) {
                    th.errors.methods[index].min_payment = 'Это поле обязательно'
                    checked = true
                    return
                }
                if(!item.image) {
                    th.errors.methods[index].image = 'Это поле обязательно'
                    checked = true
                    return
                }
            });

            if(checked) {
                return
            }

            var merchant_ids = []

            for (let merchant of this.data.merchants) {
                merchant_ids.push(merchant.id)
            }
            
            this.btn_preloader = true
            this.$axios.post('/paysystems/update', JSON.stringify({
                id: this.$props.id,
                name: this.data.name,
                manual_withdraw: this.data.manual_withdraw,
                merchants: merchant_ids,
                controller: this.data.controller,
                methods: this.data.methods,
                status: this.data.status.key
            }))
            .then((response) => {
                this.btn_preloader = false
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":
                        this.$toast.success(response.message)

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                this.btn_preloader = false
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errorByPatch(this.errors, response.field, response.message)
                    } else {
                        this.$toast.error(response.message)
                    }
                }
            })
        },
        async getMerchants () {
            await this.$axios.post('/merchants/items')
            .then((response) => {
                this.preloader = false
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':
                        for (let item of response.data) {
                            this.utils.merchants.push({
                                name: item.name,
                                id: item.id
                            })
                        }

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })     
        },
        getImages () {
            this.$axios.post('/paysystems/image/items')
            .then((response) => {
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':
                        this.utils.images = response.data

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })     
        },
        uploadImage (e) {
            var formData = new FormData()
            formData.append("image", e.target.files[0])

            this.$confirm('Вы уверены что хотите загрузить это изображение?').then(() => {
                this.$axios.post('/paysystems/image/upload', formData)
                .then((response) => {
                    response = response.data
                    switch (response.status) {
                        case 'error':
                            this.$toast.error(response.message)
                            break
                        case 'success':
                            this.getImages()
                            this.$toast.success(response.message)

                            this.setUser(response.user)
                            break
                        default:
                            this.$toast.error('Unknown error')
                    }
                }).catch((error) => {
                    let response = error.response.data
                    this.setUser(response.user)

                    if('status' in response && response.status == 'error') {
                        this.$toast.error(response.message)
                    }
                })  
            })
        },
        deleteImage (image) {
            this.$confirm('Вы уверены что хотите удалить это изображение?').then(() => {
                this.$axios.post('/paysystems/image/delete', JSON.stringify({
                    image: image
                }))
                .then((response) => {
                    response = response.data
                    switch (response.status) {
                        case 'error':
                            this.$toast.error(response.message)
                            break
                        case 'success':
                            this.getImages()
                            this.$toast.success(response.message)

                            this.setUser(response.user)
                            break
                        default:
                            this.$toast.error('Unknown error')
                    }
                }).catch((error) => {
                    let response = error.response.data
                    this.setUser(response.user)

                    if('status' in response && response.status == 'error') {
                        this.$toast.error(response.message)
                    }
                })  
            })
        },
        errorByPatch (layer, path, value) {
            path = path.split('.')

            path.forEach((value, index) => {
                if (!isNaN(value)) {
                    path[index] = parseInt(value)
                }
            })

            for (var i = 0; i < path.length; i++) {
                if (value != null && i + 1 === path.length) {
                    layer[path[i]] = value
                }
                layer = layer[path[i]]
            }
            return layer
        },
        newMethod () {
            this.data.methods.push({
                name: null,
                slug: null,
                percent: null,
                min_payment: null,
                image: null,
                status: 0
            })

            this.errors.methods.push({
                name: false,
                slug: false,
                percent: false,
                min_payment: false,
                image: false
            })
        },
        deleteMethod (index) {
            if(this.data.methods.length > 1) {
                this.data.methods.splice(index, 1)
            }
        },
        changeStatus (index) {
            this.data.methods[index].status = this.data.methods[index].status ? 0 : 1
        },
        changeType () {
            this.data.controller = null
            this.data.methods = [{
                name: null,
                slug: null,
                percent: null,
                min_payment: null,
                image: null,
                status: 0
            }]
        }
    },
    beforeMount() {
        this.getItem()
        this.getImages()
    }
}
</script>

<style lang="css" scoped>
    ::v-deep .vs__clear {
        display: none;
    }
</style>
