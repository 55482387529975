<template>
    <main class="main">
        <div class="container-fluid px-0" data-layout="container">

            <aside-main/>

            <header-main/>

            <div class="content">

                <div class="card-header mb-5 d-flex flex-between-center">
                    <div class="d-flex align-items-center">
                        <h2 class="text-bold text-1100">
                            Промо акции
                            <span class="fw-normal text-700 ms-2">({{ items_count }})</span>
                        </h2>
                    </div>

                    <div class="d-flex">
                        <router-link
                            :to="{name: 'PromotionsCreate'}"
                            class="btn btn-primary me-1 mb-1 float_right">
                            Создать
                        </router-link>
                    </div>
                </div>

                <div class="row align-items-center justify-content-between g-3 mb-3">
                    <div class="col-sm-6 col-md-6">
                        <div class="form-icon-container">
                            <vue-select
                                @input="changeSearch"
                                @search="searchUsers"
                                v-model="search.user"
                                :options="utils.users" 
                                placeholder="Пользователь"
                                label="email">
                                <template v-slot:option="option">
                                    <span>{{ option.email }}</span>
                                </template>
                                <template v-slot:no-options="{ search, searching }">
                                    <template v-if="searching && search.length >= 3">
                                        Ничего не найдено по запросу <em>{{ search }}</em>.
                                    </template>
                                    <span v-else>Введите не менее 3 символов</span>
                                </template>
                            </vue-select>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-6">
                        <div class="form-icon-container">
                            <vue-select
                                @input="changeSearch"
                                v-model="search.status"
                                :options="utils.statuses"
                                placeholder="Статус"
                                label="name">
                                <template v-slot:no-options="{ search, searching }">
                                  <template v-if="searching">
                                     Ничего не найдено по запросу <em>{{ search }}</em>.
                                  </template>
                                </template>
                            </vue-select>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-6">
                        <div class="form-icon-container">
                            <vue-select
                                @input="changeSearch"
                                v-model="search.action"
                                :options="utils.actions"
                                placeholder="Действие"
                                label="name">
                                <template v-slot:no-options="{ search, searching }">
                                  <template v-if="searching">
                                     Ничего не найдено по запросу <em>{{ search }}</em>.
                                  </template>
                                </template>
                            </vue-select>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-6">
                        <div class="form-icon-container">
                            <input
                                @input="changeSearch"
                                v-model="search.promocode"
                                type="text" 
                                placeholder="Промокод" 
                                class="form-control form-icon-input">
                            <span class="fa-regular fa-magnifying-glass text-500 fs--1 form-icon"></span>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-6">
                        <div class="form-icon-container">
                            <date-range-picker
                                @update="changeSearch"
                                :locale-data="datepiker"
                                :opens="pikerparams.opens"
                                :always-show-calendars="pikerparams.showcalendars"
                                :close-on-esc="pikerparams.closeonesc"
                                :auto-apply="pikerparams.autoapply"
                                :time-picker="pikerparams.timePicker"
                                :time-picker24-hour="pikerparams.timePicker24Hour"
                                v-model="search.date"
                                class="daterange-picker-medium">
                                <template #ranges="ranges">
                                  <div class="ranges">
                                    <ul>
                                      <li 
                                        v-for="(range, name, key) in pikerranges" 
                                        :key="name" 
                                        @click="ranges.clickRange(range, key)">
                                            {{name}}
                                      </li>
                                    </ul>
                                  </div>
                                </template>
                            </date-range-picker>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-6">
                        <div class="form-icon-container">
                            <vue-select
                                @input="changeSearch"
                                v-model="search.merchant"
                                :options="utils.merchants" 
                                placeholder="Мерчант"
                                label="name">
                                <template v-slot:no-options="{ search, searching }">
                                  <template v-if="searching">
                                     Ничего не найдено по запросу <em>{{ search }}</em>.
                                  </template>
                                </template>
                            </vue-select>
                        </div>
                    </div>
                </div>

                <div class="card-body p-0 spinner-center" v-if="preloader">
                    <div class="p-4 code-to-copy">
                        <div class="spinner-border" role="status"></div>
                    </div>
                </div>
                

                <div class="mx-n4 mx-lg-n6 px-4 px-lg-6 mb-9 bg-white border-y border-300 mt-2 position-relative top-1" v-show="!preloader">
                    <div class="table-responsive scrollbar ms-n1 ps-1" style="padding-bottom: 5px">
                        <div class="table table-responsive-small">
                            <datatable
                                :filter="search.search_change"
                                :columns="columns" 
                                :data="getItems"
                                :per-page="pager.per_page">
                                <template scope="{ row, columns }">
                                    <tr>
                                        <td>{{ row.id }}</td>
                                        <td><router-link :to="{name: 'UsersItem', params: {id: row.user_id}}">{{ row.email }}</router-link></td>
                                        <td>{{ row.merchant_name }}</td>
                                        <td>{{ utils.types.find(el => el.key == row.type).name }}</td>
                                        <td>{{ utils.actions.find(el => el.key == row.action).name }}</td>
                                        <td>{{ row.wager }}x</td>
                                        <td>{{ parseFloat(row.amount).toFixed(2) }}</td>
                                        <td>{{ parseFloat(row.to_wager_amount).toFixed(2) }}</td>
                                        <td class="align-middle white-space-nowrap ps-3">
                                            <p class="text-800 fs--2 mb-0" style="text-align: center">{{ parseFloat(row.wagered_amount).toFixed(2) }}</p>
                                            <div class="progress" style="height:3px;">
                                              <div class="progress-bar bg-success" :style="`width: ${parseFloat(row.wagered_amount) * 100 / parseFloat(row.to_wager_amount)}%`"></div>
                                            </div>
                                        </td>
                                        <td>{{ $moment(row.start_at).format('YYYY-MM-DD H:mm:ss') }}</td>
                                        <td>{{ $moment(row.expire_at).format('YYYY-MM-DD H:mm:ss') }}</td>
                                        <td>{{ $moment(row.created_at).format('YYYY-MM-DD H:mm:ss') }}</td>
                                        <td>
                                            <span class="badge badge-casino fs--3 badge-casino-warning" v-if="row.status == 0">
                                                {{ utils.statuses.find(el => el.key == row.status).name }}
                                                <i class="fa-regular fa-clock"></i>
                                            </span>
                                            <span class="badge badge-casino fs--3 badge-casino-primary" v-else-if="row.status == 1">
                                                {{ utils.statuses.find(el => el.key == row.status).name }}
                                                <i class="fa-solid fa-check"></i>
                                            </span>
                                            <span class="badge badge-casino fs--3 badge-casino-info" v-else-if="row.status == 2">
                                                {{ utils.statuses.find(el => el.key == row.status).name }}
                                                <i class="fa-regular fa-check"></i>
                                            </span>
                                            <span class="badge badge-casino fs--3 badge-casino-danger" v-else-if="row.status == 3">
                                                {{ utils.statuses.find(el => el.key == row.status).name }}
                                                <i class="fa-solid fa-xmark"></i>
                                            </span>
                                            <span class="badge badge-casino fs--3 badge-casino-success" v-else-if="row.status == 4">
                                                {{ utils.statuses.find(el => el.key == row.status).name }}
                                                <i class="fa-regular fa-circle-check"></i>
                                            </span>
                                            <span class="badge badge-casino fs--3 badge-casino-secondary" v-else-if="row.status == 5">
                                                {{ utils.statuses.find(el => el.key == row.status).name }}
                                                <i class="fa-regular fa-clock"></i>
                                            </span>
                                        </td>
                                        <td>
                                            <router-link
                                                :to="{name: 'PromotionsItem', params: {id: row.id}}"
                                                content="Редактировать" 
                                                v-tippy="{arrow : true, arrowType: 'round'}"
                                                class="btn btn-soft-primary btn-sm me-1 mb-1">
                                                <i class="fa-regular fa-pen-to-square"></i>
                                            </router-link>
                                            <button
                                                @click="deleteItem(row.id)"
                                                content="Удалить" 
                                                v-tippy="{arrow : true, arrowType: 'round'}"
                                                class="btn btn-soft-danger btn-sm me-1 mb-1">
                                                <i class="fa-regular fa-trash"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </template>

                                <template name="no-result">
                                    Ничего не найдено
                                </template>
                            </datatable>
                        </div>

                        <div class="col-auto d-flex">
                            <ul class="mb-3 pagination">
                                <datatable-pager v-model="pager.page" type="abbreviated"></datatable-pager>
                            </ul>
                        </div>
                    </div>
                </div>
                
                <footer-main/>

            </div>

        </div>
    </main>
</template>

<script>
export default {
    data () {
        return {
            preloader: true,
            pager: {
                per_page: 25,
                page: 0
            },
            items_count: 0,
            search: {
                search_change: null,
                user: null,
                status: null,
                action: null,
                promocode: null,
                merchant: null,
                date: {
                    startDate: this.$moment().set({date: this.$moment().get('date') - 30}).format('Y-MM-DD HH:mm:ss'),
                    endDate: this.$moment().format('Y-MM-DD HH:mm:ss')
                }
            },
            columns: [
                {label: 'ID'},
                {label: 'Пользователь'},
                {label: 'Мерчант'},
                {label: 'Тип'},
                {label: 'Действие'},
                {label: 'Wager'},
                {label: 'Сумма'},
                {label: 'Сумма отыгрыша'},
                {label: 'Отыграно'},
                {label: 'Доступен c'},
                {label: 'Истекает'},
                {label: 'Дата создания'},
                {label: 'Статус'}
            ],
            utils: {
                merchants: [],
                users: [],
                types: [
                    {
                        name: 'Ваучер',
                        key: 1
                    },
                    /*{
                        name: 'Бесплатные вращения',
                        key: 2
                    },*/
                    {
                        name: 'Кэш Бонус',
                        key: 3
                    }
                ],
                statuses: [
                    {
                        name: 'В ожидании',
                        key: 0
                    },
                    {
                        name: 'Активирован',
                        key: 1
                    },
                    {
                        name: 'Подтвержден',
                        key: 2
                    },
                    {
                        name: 'Отменен',
                        key: 3
                    },
                    {
                        name: 'Отыгран',
                        key: 4
                    },
                    {
                        name: 'Истек',
                        key: 5
                    }
                ],
                actions: [
                    {
                        name: 'За депозит',
                        key: 1
                    },
                    {
                        name: 'Бездепозитный',
                        key: 2
                    },
                    {
                        name: 'За регистрацию',
                        key: 3
                    },
                    {
                        name: 'Промокод',
                        key: 4
                    }
                ]
            },
            datepiker: {
                direction: 'ltr',
                format: 'yyyy.mm.dd HH:MM',
                separator: ' - ',
                applyLabel: 'Применить',
                cancelLabel: 'Отмена',
                weekLabel: 'W',
                customRangeLabel: 'Настраиваемый диапазон',
                daysOfWeek: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
                monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
                firstDay: 0
            },
            pikerparams: {
                showcalendars: true,
                closeonesc: true,
                autoapply: false,
                timePicker: true,
                timePicker24Hour: true,
                opens: 'right'
            },
            pikerranges: {
                'Один день': [this.$moment().set({date: this.$moment().get('date') - 1}).toDate(), this.$moment().toDate()],
                'Одна неделя': [this.$moment().set({date: this.$moment().get('date') - 7}).toDate(), this.$moment().toDate()],
                'Один месяц': [this.$moment().set({date: this.$moment().get('date') - 30}).toDate(), this.$moment().toDate()],
                'Два месяца': [this.$moment().set({date: this.$moment().get('date') - 60}).toDate(), this.$moment().toDate()],
                'Три месяца': [this.$moment().set({date: this.$moment().get('date') - 90}).toDate(), this.$moment().toDate()],
                'Пол года': [this.$moment().set({date: this.$moment().get('date') - 180}).toDate(), this.$moment().toDate()],
                'Один год': [this.$moment().set({date: this.$moment().get('date') - 365}).toDate(), this.$moment().toDate()]
            }
        }
    },
    methods: {
        async getItems ({ sortBy, sortDir, perPage, page }) {
            this.preloader = true
            var items = await this.$axios.post('/promotions/items', JSON.stringify({
                pager: {
                    per_page: this.pager.per_page,
                    page: page - 1
                },
                sort: {
                    by: sortBy,
                    dir: sortDir
                },
                search: {
                    user: this.search.user ? this.search.user.id : null,
                    status: this.search.status != null ? this.search.status.key : null,
                    action: this.search.action != null ? this.search.action.key : null,
                    promocode: this.search.promocode,
                    merchant: this.search.merchant != null ? this.search.merchant.id : null,
                    date: {
                        start: this.$moment(this.search.date.startDate).format('YYYY-MM-DD H:mm:ss'),
                        expire: this.$moment(this.search.date.endDate).format('YYYY-MM-DD H:mm:ss')
                    }
                }
            }))
            .then((response) => {
                this.preloader = false
                this.setUser(response.data.user)
                return response.data
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)
                
                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })

            if(items && items.status == 'success') {
                this.items_count = items.data.count
                return {
                    rows: items.data.items,
                    totalRowCount: items.data.count
                }
            }

            return {
                rows: [],
                totalRowCount: 0
            }
        },
        deleteItem (id) {
            this.$confirm('Вы уверены что хотите удалить промо акцию?').then(() => {
                this.$axios.post('/promotions/delete', JSON.stringify({
                    id: id
                }))
                .then((response) => {
                    response = response.data
                    switch (response.status) {
                        case "error":
                            this.$toast.error(response.message)
                            break
                        case "success":
                            this.changeSearch()
                            this.$toast.success(response.message)

                            this.setUser(response.user)
                            break
                        default:
                            this.$toast.error('Unknown error')
                    }
                }).catch((error) => {
                    let response = error.response.data
                    this.setUser(response.user)

                    if('status' in response && response.status == 'error') {
                        this.$toast.error(response.message)
                    }
                })
            })
        },
        searchUsers (search, loading) {
            if(search.length >= 3) {
                loading(true)
                 this.$axios.post('/users/search/identificator', JSON.stringify({
                    search: search
                 }))
                .then((response) => {
                    response = response.data
                    switch (response.status) {
                        case "error":
                            this.$toast.error(response.message)
                            break
                        case "success":
                            loading(false)
                            this.utils.users = []
                            for (let user of response.data) {
                                this.utils.users.push({
                                    id: user.id,
                                    email: user.email
                                })
                            }

                            this.setUser(response.user)
                            break
                        default:
                            this.$toast.error('Unknown error')
                    }
                }).catch((error) => {
                    let response = error.response.data
                    this.setUser(response.user)

                    if('status' in response && response.status == 'error') {
                        if('field' in response) {
                            this.errors[response.field] = response.message
                        } else {
                            this.$toast.error(response.message)
                        }
                    }
                })
            }
        },
        getMerchants () {
            this.$axios.post('/merchants/items')
            .then((response) => {
                this.preloader = false
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':
                        for (let item of response.data) {
                            this.utils.merchants.push({
                                name: item.name,
                                id: item.id
                            })
                        }

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })     
        },
        changeSearch () {
            this.search.search_change = String(Date.now())
        },
    },
    beforeMount() {
        this.getMerchants()
    }
}
</script>
