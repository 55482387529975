<template>
    <main class="main">
        <div class="container-fluid px-0" data-layout="container">

            <aside-main/>

            <header-main/>

            <div class="content">
                <form class="mb-9" v-on:submit.prevent="createItem">
                    <div class="row justify-content-between align-items-end g-3 mb-5">
                        <div class="col-12 col-sm-auto col-xl-8">
                            <h2>Создать Турнир</h2>
                        </div>
                        <div class="col-12 col-sm-auto col-xl-4">
                            <div class="d-flex">
                                <button type="submit" class="btn btn-primary px-5 w-100 text-nowrap" :class="{'disabled': btn_preloader}">
                                    <span class="spinner-border spinner-border-sm spinner-border-custom" role="status" v-if="btn_preloader"></span>
                                    <span>Создать</span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="card-body p-0 spinner-center" v-if="preloader">
                        <div class="p-4 code-to-copy">
                            <div class="spinner-border" role="status"></div>
                        </div>
                    </div>

                    <div class="row g-5" v-if="!preloader">
                        <h4 class="fs-1">Основная информация</h4>

                        <div class="row g-3">
                            <div class="col-sm-6 col-md-12">
                                <div class="form-floating">
                                    <input
                                        v-model="data.name"
                                        @input="errors.name = null"
                                        :class="{'is-invalid': errors.name}"
                                        placeholder="Название"
                                        class="form-control" 
                                        type="text"/>
                                    <label>Название</label>
                                    <div class="invalid-feedback">{{errors.name}}</div>
                                </div>
                            </div>

                            <div class="col-sm-6 col-md-6">
                                <div class="form-floating v-select-medium" :class="{'v-select-error': errors.type}">
                                    <vue-select
                                        @input="errors.type = null"
                                        v-model="data.type"
                                        :options="utils.types" 
                                        placeholder="Тип"
                                        label="name">
                                        <template v-slot:no-options="{ search, searching }">
                                          <template v-if="searching">
                                             Ничего не найдено по запросу <em>{{ search }}</em>.
                                          </template>
                                        </template>
                                    </vue-select>
                                    <label>Тип</label>
                                    <div class="invalid-feedback">{{errors.type}}</div>
                                </div>
                            </div>

                            <div class="col-sm-6 col-md-6">
                                <div class="form-floating">
                                    <date-range-picker
                                        :class="{'is-invalid': errors.date}"
                                        @update="errors.date = null"
                                        :locale-data="datepiker"
                                        :opens="pikerparams.opens"
                                        :always-show-calendars="pikerparams.showcalendars"
                                        :close-on-esc="pikerparams.closeonesc"
                                        :auto-apply="pikerparams.autoapply"
                                        :time-picker="pikerparams.timePicker"
                                        :time-picker24-hour="pikerparams.timePicker24Hour"
                                        v-model="data.date">
                                        <template #ranges="ranges">
                                          <div class="ranges">
                                            <ul>
                                              <li 
                                                v-for="(range, name, key) in pikerranges" 
                                                :key="name" 
                                                @click="ranges.clickRange(range, key)">
                                                    {{name}}
                                              </li>
                                            </ul>
                                          </div>
                                        </template>
                                    </date-range-picker>

                                    <label>Период проведения</label>
                                    <div class="invalid-feedback">{{errors.date}}</div>
                                </div>
                            </div>

                            <div class="col-sm-6 col-md-6">
                                <div class="form-floating banner-input-raw">
                                    <div 
                                        @click="$refs.imageSmall.click()"
                                        content="Выберите изображение" 
                                        v-tippy="{arrow : true, arrowType: 'round'}"
                                        class="banner-input-number">
                                        <i class="fa-solid fa-plus vertical-center-item"></i>
                                    </div>
                                    <input
                                        v-model="data.images.small.name"
                                        @input="errors.image_small = null"
                                        :class="{'is-invalid': errors.image_small}"
                                        disabled
                                        placeholder="Превью 550x300"
                                        class="form-control" 
                                        type="text"/>
                                    <label>Превью 550x300</label>
                                    <input ref="imageSmall" type="file" @input="imageSmall" accept=".png, .jpg, .jpeg, .gif" style="display: none;">
                                    <button
                                        @click="deleteImageSmall"
                                        content="Удалить" 
                                        v-tippy="{arrow : true, arrowType: 'round'}"
                                        type="button" 
                                        class="btn btn-soft-danger banner-input-button-right">
                                        <span class="vertical-center-item">
                                            <i class="fa-regular fa-trash"></i>
                                        </span>
                                    </button>
                                </div>
                                <div class="invalid-feedback">{{errors.image_small}}</div>
                            </div>

                            <div class="col-sm-6 col-md-6">
                                <div class="form-floating banner-input-raw">
                                    <div 
                                        @click="$refs.imageBig.click()"
                                        content="Выберите изображение" 
                                        v-tippy="{arrow : true, arrowType: 'round'}"
                                        class="banner-input-number">
                                        <i class="fa-solid fa-plus vertical-center-item"></i>
                                    </div>
                                    <input
                                        v-model="data.images.big.name"
                                        @input="errors.image_big = null"
                                        :class="{'is-invalid': errors.image_big}"
                                        disabled
                                        placeholder="Изображение 2000x685"
                                        class="form-control" 
                                        type="text"/>
                                    <label>Изображение 2000x685</label>
                                    <input ref="imageBig" type="file" @input="imageBig" accept=".png, .jpg, .jpeg, .gif" style="display: none;">
                                    <button
                                        @click="deleteImageBig"
                                        content="Удалить" 
                                        v-tippy="{arrow : true, arrowType: 'round'}"
                                        type="button" 
                                        class="btn btn-soft-danger banner-input-button-right">
                                        <span class="vertical-center-item">
                                            <i class="fa-regular fa-trash"></i>
                                        </span>
                                    </button>
                                </div>
                                <div class="invalid-feedback">{{errors.image_big}}</div>
                            </div> 

                            <!--
                            <div class="col-sm-6 col-md-12">
                                <div class="form-floating v-select-medium" :class="{'v-select-error': errors.games}">
                                    <vue-select
                                        multiple
                                        @input="errors.games = null"
                                        @search="searchGames"
                                        v-model="data.games"
                                        :options="utils.games" 
                                        placeholder="Игры"
                                        label="name">
                                        <template v-slot:option="option">
                                            <img style="width: 50px;margin-right: 20px;height: 100%; height: 35px;" :src="option.image"/>
                                            <span>{{ option.name }}</span>
                                            <template v-if="option.is_mobile">
                                                <span class="badge badge-casino fs--3 badge-casino-warning" style="float: right;margin-top: 10px;margin-left: 10px;">Mobile</span>
                                            </template>
                                            <template v-if="option.is_desktop">
                                                <span class="badge badge-casino fs--3 badge-casino-success" style="float: right;margin-top: 10px;margin-left: 10px;">Desktop</span>
                                            </template>
                                        </template>
                                        <template v-slot:no-options="{ search, searching }">
                                            <template v-if="searching && search.length >= 3">
                                                Ничего не найдено по запросу <em>{{ search }}</em>.
                                            </template>
                                            <span v-else>Введите не менее 3 символов</span>
                                        </template>
                                    </vue-select>
                                    <label>Игры</label>
                                    <div class="invalid-feedback">{{errors.games}}</div>
                                </div>
                            </div>-->
                        </div>

                        <div class="row g-3">

                            <div class="card-header p-2 mt-3 d-flex flex-between-center" style="margin-bottom: -10px;">
                                <div class="d-flex align-items-center">
                                    <h4 class="fs-1">Призовой фонд <span class="text-700">{{ totalPrize() }} $</span></h4>
                                </div>

                                <div class="d-flex">
                                    <a class="btn btn-primary mb-1 float_right" @click="addPrize">Новый приз</a>
                                </div>
                            </div>

                            <template v-for="(item,index) in data.prizes">
                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating tournament-prize-raw">
                                        <div 
                                            content="Призовое место" 
                                            v-tippy="{arrow : true, arrowType: 'round'}"
                                            class="tournament-prize-number">
                                            <span class="vertical-center-item">{{index + 1}}</span>
                                        </div>
                                        <input
                                            v-model="data.prizes[index]"
                                            @input="errors.prizes[index] = null"
                                            :class="{'is-invalid': errors.prizes[index]}"
                                            placeholder="Сумма выигрыша ($)"
                                            class="form-control" 
                                            type="text"/>
                                        <label>Сумма выигрыша ($)</label>

                                        <button 
                                            @click="deletePrize(index)"
                                            content="Удалить" 
                                            v-tippy="{arrow : true, arrowType: 'round'}"
                                            type="button" 
                                            class="btn btn-soft-danger tournament-button-right">
                                            <span class="vertical-center-item">
                                                <i class="fa-regular fa-trash"></i>
                                            </span>
                                        </button>
                                    </div>
                                    <div class="invalid-feedback">{{errors.prizes[index]}}</div>
                                </div>
                            </template>

                        </div>

                        <div class="row g-3">
                            <h4 class="fs-1 mt-5">Мин. ставка/выигрыш</h4>
                            <template v-for="(item, key) in data.merchants">
                                <div class="col-sm-6 col-md-4">
                                    <div class="form-floating v-select-medium" :class="{'v-select-error': errors.merchants[key].merchant}">
                                        <vue-select
                                            disabled
                                            v-model="data.merchants[key].merchant"
                                            label="name">
                                            <template v-slot:no-options="{ search, searching }">
                                              <template v-if="searching">
                                                 Ничего не найдено по запросу <em>{{ search }}</em>.
                                              </template>
                                            </template>
                                        </vue-select>
                                        <label>Мерчант</label>
                                        <div class="invalid-feedback">{{errors.merchants[key].merchant}}</div>
                                    </div>
                                </div>
                                
                                <div class="col-sm-6 col-md-4">
                                    <div class="form-floating">
                                        <input
                                            v-model="data.merchants[key].min_bet"
                                            @input="errors.merchants[key].min_bet = null"
                                            :class="{'is-invalid': errors.merchants[key].min_bet}"
                                            placeholder="Мин. ставка"
                                            class="form-control" 
                                            type="text"/>
                                        <label>Мин. ставка</label>
                                        <div class="invalid-feedback">{{errors.merchants[key].min_bet}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-4">
                                    <div class="form-floating">
                                        <input
                                            v-model="data.merchants[key].min_win"
                                            @input="errors.merchants[key].min_win = null"
                                            :class="{'is-invalid': errors.merchants[key].min_win}"
                                            placeholder="Мин. выигрыш"
                                            class="form-control" 
                                            type="text"/>
                                        <label>Мин. выигрыш</label>
                                        <div class="invalid-feedback">{{errors.merchants[key].min_win}}</div>
                                    </div>
                                </div>
                            </template>
                        </div>

                    </div>
                </form>
                
                <footer-main/>

            </div>

        </div>
    </main>
</template>

<script>
export default {
    data () {
        return {
            preloader: true,
            btn_preloader: false,
            data: {
                name: null,
                type: {
                    name: 'Сумма ставок',
                    key: 1
                },
                merchants: [],
                images: {
                    small: {
                        name: null,
                        file: null
                    },
                    big: {
                        name: null,
                        file: null
                    }
                },
                total_amount: null,
                prizes: [1000, 500],
                //games: [],
                date: {
                    startDate: this.$moment().format('Y-MM-DD HH:mm:ss'),
                    endDate: this.$moment().set({date: this.$moment().get('date') + 30}).format('Y-MM-DD HH:mm:ss')
                }
            },
            errors: {
                name: false,
                type: false,
                merchants: [],
                total_amount: false,
                prizes: [false, false],
                //games: false,
                date: false,
                status: false,
                image_small: false,
                image_big: false
            },
            utils: {
                merchants: [],
                //games: [],
                types: [
                    {
                        name: 'Сумма ставок',
                        key: 1
                    },
                    {
                        name: 'Максимальный выигрыш',
                        key: 2
                    }
                ]
            },
            datepiker: {
                direction: 'ltr',
                format: 'yyyy.mm.dd HH:MM',
                separator: ' - ',
                applyLabel: 'Применить',
                cancelLabel: 'Отмена',
                weekLabel: 'W',
                customRangeLabel: 'Настраиваемый диапазон',
                daysOfWeek: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
                monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
                firstDay: 0
            },
            pikerparams: {
                showcalendars: true,
                closeonesc: true,
                autoapply: false,
                timePicker: true,
                timePicker24Hour: true,
                opens: 'left'
            },
            pikerranges: {
                'Один день': [this.$moment().toDate(), this.$moment().set({date: this.$moment().get('date') + 1}).toDate()],
                'Одна неделя': [this.$moment().toDate(), this.$moment().set({date: this.$moment().get('date') + 7}).toDate()],
                'Один месяц': [this.$moment().toDate(), this.$moment().set({date: this.$moment().get('date') + 30}).toDate()],
                'Два месяца': [this.$moment().toDate(), this.$moment().set({date: this.$moment().get('date') + 60}).toDate()],
                'Три месяца': [this.$moment().toDate(), this.$moment().set({date: this.$moment().get('date') + 90}).toDate()],
                'Пол года': [this.$moment().toDate(), this.$moment().set({date: this.$moment().get('date') + 180}).toDate()],
                'Один год': [this.$moment().toDate(), this.$moment().set({date: this.$moment().get('date') + 365}).toDate()]
            }
        }
    },
    methods: {
        createItem () {
            if(!this.data.name) {
                this.errors.name = 'Это поле обязательно'
                return
            }
            if(!this.data.type) {
                this.errors.type = 'Это поле обязательно'
                return
            }
            if(!this.data.date) {
                this.errors.date = 'Это поле обязательно'
                return
            }
            /*if(!this.data.games || !this.data.games.length) {
                this.errors.games = 'Это поле обязательно'
                return
            }*/
            if(!this.data.prizes || !this.data.prizes.length) {
                this.errors.prizes = 'Это поле обязательно'
                return
            }

            if(!this.data.images.small.file) {
                this.errors.image_small = 'Это поле обязательно'
                return
            }
            if(!this.data.images.big.file) {
                this.errors.image_big = 'Это поле обязательно'
                return
            }

            for (var i = 0; i < this.data.merchants.length; i++) {
                if (!this.data.merchants[i].merchant) {
                    this.errors.merchants[i].merchant = 'Это поле обязательно'
                    return
                }

                if (!this.isNumeric(this.data.merchants[i].min_bet) || !this.data.merchants[i].min_bet) {
                    this.errors.merchants[i].min_bet = 'Это поле обязательно'
                    return
                }

                if (!this.isNumeric(this.data.merchants[i].min_win) || !this.data.merchants[i].min_win) {
                    this.errors.merchants[i].min_win = 'Это поле обязательно'
                    return
                }

            }

            /*var games = []
            for (let game of this.data.games) {
                games.push(game.id)
            }*/

            var prizes = []
            for (let prize of this.data.prizes) {
                prizes.push(parseFloat(prize))
            }

            let formData = this.objectToFormData(new FormData(), {
                name: this.data.name,
                type: this.data.type.key,
                //games: games,
                prizes: prizes,
                merchants: this.data.merchants,
                date: {
                    start: this.$moment(this.data.date.startDate).format('YYYY-MM-DD H:mm:ss'),
                    expire: this.$moment(this.data.date.endDate).format('YYYY-MM-DD H:mm:ss')
                },
                image_small: this.data.images.small.file,
                image_big: this.data.images.big.file
            })
            
            this.btn_preloader = true
            this.$axios.post('/tournaments/create', formData)
            .then((response) => {
                this.btn_preloader = false
                response = response.data
                switch (response.status) {
                    case "error":
                        this.$toast.error(response.message)
                        break
                    case "success":
                        this.$router.push({name: 'TournamentsItems', params: {status: 'active'}})
                        this.$toast.success(response.message)

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                this.btn_preloader = false
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errorByPatch(this.errors, response.field, response.message)
                    } else {
                        this.$toast.error(response.message)
                    }
                }
            })
        },
        async getMerchants () {
            await this.$axios.post('/merchants/items')
            .then((response) => {
                this.preloader = false
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':
                        for (let item of response.data) {
                            this.utils.merchants.push({
                                name: item.name,
                                currency: item.currency,
                                id: item.id
                            })

                            this.data.merchants.push({
                                merchant: {
                                    name: item.name,
                                    key: item.id
                                },
                                min_bet: null,
                                min_win: null
                            })

                            this.errors.merchants.push({
                                merchant: false,
                                min_bet: false,
                                min_win: false
                            })
                        }

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errorByPatch(this.errors, response.field, response.message)
                    } else {
                        this.$toast.error(response.message)
                    }
                }
            })     
        },
        /*searchGames (search, loading) {
            this.utils.games = []
            if(search.length >= 3) {
                loading(true)
                 this.$axios.post('/games/search/identificator', JSON.stringify({
                    search: search
                 }))
                .then((response) => {
                    response = response.data
                    switch (response.status) {
                        case "error":
                            this.$toast.error(response.message)
                            break
                        case "success":
                            loading(false)
                            for (let game of response.data) {
                                this.utils.games.push({
                                    id: game.id,
                                    name: game.name,
                                    image: game.image,
                                    is_mobile: game.is_mobile,
                                    is_desktop: game.is_desktop
                                })
                            }

                            this.setUser(response.user)
                            break
                        default:
                            this.$toast.error('Unknown error')
                    }
                }).catch((error) => {
                    let response = error.response.data
                    this.setUser(response.user)

                    if('status' in response && response.status == 'error') {
                        if('field' in response) {
                            this.errors[response.field] = response.message
                        } else {
                            this.$toast.error(response.message)
                        }
                    }
                })
            }
        },*/
        deleteImageSmall () {
            this.data.images.small.name = null
            this.data.images.small.file = null
        },
        deleteImageBig () {
            this.data.images.big.name = null
            this.data.images.big.file = null
        },
        imageSmall (e) {
            var file = e.target.files[0]

            if(file) {
                this.data.images.small.name = null

                if (file.size > 5000000) {
                    this.errors.image_small = 'Размер изображения не должен превышать 5MB'
                    return
                }

                var th = this
                var reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = (e) => {
                    var image = new Image()
                    image.src = e.target.result
                    image.onload = () => {
                      var {height, width} = image
                      if (height != 300 || width != 550) {
                        th.errors.image_small = 'Размер должен быть 550x300'
                        return false
                      }
                      th.errors.image_small = false
                      return true
                    }
                }

                this.data.images.small.name = file.name
                this.data.images.small.file = file
            }
        },
        imageBig (e) {
            var file = e.target.files[0]

            if(file) {
                this.data.images.big.name = null

                if (file.size > 5000000) {
                    this.errors.image_big = 'Размер изображения не должен превышать 5MB'
                    return
                }

                var th = this
                var reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = (e) => {
                    var image = new Image()
                    image.src = e.target.result
                    image.onload = () => {
                      var {height, width} = image
                      if (height < 685 || width < 2000) {
                        th.errors.image_big = 'Размер должен быть 2000x685 или больше'
                        return false
                      }
                      th.errors.image_big = false
                      return true
                    }
                }

                this.data.images.big.name = file.name
                this.data.images.big.file = file
            }
        },
        objectToFormData(formData, data, root) {
            root = root || ''
            if (data instanceof File) {
                formData.append(root, data)
            } else if (Array.isArray(data)) {
                for (var i = 0; i < data.length; i++) {
                    this.objectToFormData(formData, data[i], root + '[' + i + ']')
                }
            } else if (typeof data === 'object' && data) {
                let i = 0
                for (var key in data) {
                    if (data.hasOwnProperty(key)) {
                        if (root === '') {
                            this.objectToFormData(formData, data[key], key)
                        } else {
                            this.objectToFormData(formData, data[key], root + '[' + key + ']')
                        }
                    }
                    i++
                }
            } else {
                if (data !== null && typeof data !== 'undefined') {
                    formData.append(root, data)
                }
            }

            return formData
        },
        errorByPatch (layer, path, value) {
            path = path.split('.')

            path.forEach((value, index) => {
                if (!isNaN(value)) {
                    path[index] = parseInt(value)
                }
            })

            for (var i = 0; i < path.length; i++) {
                if (value != null && i + 1 === path.length) {
                    layer[path[i]] = value
                }
                layer = layer[path[i]]
            }
            return layer
        },
        totalPrize () {
            var num = 0

            for (let prize of this.data.prizes) {
                if(!isNaN(prize)) {
                   num += parseFloat(prize)
                }
            }

            return num
        },
        addPrize () {
            this.data.prizes.push(0)
            this.errors.prizes.push(false)
        },
        deletePrize (index) {
            if(this.data.prizes.length > 2) {
                this.data.prizes.splice(index, 1)
                this.errors.prizes.splice(index, 1)
            }
        },
        isNumeric (num) {
            return !isNaN(num)
        }
    },
    async beforeMount () {
        await this.getMerchants()
    }
}
</script>

<style lang="css" scoped>
    ::v-deep .vs__clear {
        display: none;
    }
</style>
