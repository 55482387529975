<template>
    <main class="main">
        <div class="container-fluid px-0" data-layout="container">

            <aside-main/>

            <header-main/>

            <div class="content">

                <div class="card-body p-0 spinner-center" v-if="preloader">
                    <div class="p-4 code-to-copy">
                        <div class="spinner-border" role="status"></div>
                    </div>
                </div>

                <form class="mb-9" v-on:submit.prevent="updateItem" v-if="!preloader">
                    <div class="row justify-content-between align-items-end g-3 mb-5">
                        <div class="col-12 col-sm-auto col-xl-8">
                            <h2>Карточка пользователя</h2>
                        </div>
                        <div class="col-12 col-sm-auto col-xl-4">
                            <div class="d-flex">
                                <a 
                                    content="Аналитика пользователя" 
                                    v-tippy="{arrow : true, arrowType: 'round'}"
                                    class="btn btn-casino-warning btn-casino-icon me-2">
                                    <span class="fa-solid fa-chart-simple"></span>
                                </a>
                                <button
                                    :class="{'disabled': btn_preloader}"
                                    class="btn btn-primary px-5 w-100 text-nowrap" 
                                    type="submit">
                                    <span class="spinner-border spinner-border-sm spinner-border-custom" role="status" v-if="btn_preloader"></span>
                                    <span>Сохранить изменения</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="row g-5">
                        <div class="col-xl-8">
                            <h4 class="fs-1 mb-4">Основная информация</h4>
                            <div class="row g-3">
                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <input 
                                            v-model="user.email"
                                            @input="errors.user.email = null"
                                            :class="{'is-invalid': errors.user.email}"
                                            class="form-control"
                                            id="email" 
                                            type="text" 
                                            placeholder="Email"/>
                                        <label for="email">Email</label>
                                        <div class="invalid-feedback">{{errors.user.email}}</div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <input 
                                            v-model="user.username"
                                            @input="errors.user.username = null"
                                            :class="{'is-invalid': errors.user.username}"
                                            class="form-control" 
                                            id="username" 
                                            type="text" 
                                            placeholder="Username"/>
                                        <label for="username">Username</label>
                                        <div class="invalid-feedback">{{errors.user.username}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <input 
                                            v-model="user.balance"
                                            @input="errors.user.balance = null"
                                            :class="{'is-invalid': errors.user.balance}"
                                            class="form-control" 
                                            id="balance" 
                                            type="text" 
                                            placeholder="Баланс"/>
                                        <label for="balance">Баланс</label>
                                        <div class="invalid-feedback">{{errors.user.balance}}</div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <input 
                                            v-model="user.balance_bonus"
                                            @input="errors.user.balance_bonus = null"
                                            :class="{'is-invalid': errors.user.balance_bonus}"
                                            class="form-control" 
                                            id="balance_bonus" 
                                            type="text" 
                                            placeholder="Баланс (Бонусный)"/>
                                        <label for="balance_bonus">Баланс (Бонусный)</label>
                                        <div class="invalid-feedback">{{errors.user.balance_bonus}}</div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating v-select-medium merchant_select" :class="{'v-select-error': errors.user.merchant_id}">
                                        <vue-select
                                            v-model="user.merchant_id"
                                            @input="errors.user.merchant_id = null"
                                            :options="utils.merchants"
                                            placeholder="Мерчант"
                                            label="name">
                                            <template v-slot:no-options="{ search, searching }">
                                              <template v-if="searching">
                                                 Ничего не найдено по запросу <em>{{ search }}</em>.
                                              </template>
                                            </template>
                                        </vue-select>
                                        <label>Мерчант</label>
                                        <div class="invalid-feedback">{{errors.user.merchant_id}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating v-select-medium merchant_select" :class="{'v-select-error': errors.user.status}">
                                        <vue-select
                                            v-model="user.status"
                                            @input="errors.user.status = null"
                                            :options="utils.statuses"
                                            placeholder="Статус"
                                            label="name">
                                            <template v-slot:no-options="{ search, searching }">
                                              <template v-if="searching">
                                                 Ничего не найдено по запросу <em>{{ search }}</em>.
                                              </template>
                                            </template>
                                        </vue-select>
                                        <label>Статус</label>
                                        <div class="invalid-feedback">{{errors.user.status}}</div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating v-select-medium merchant_select" :class="{'v-select-error': errors.user.status_id}">
                                        <vue-select
                                            disabled
                                            v-model="user.status_id"
                                            @input="errors.user.status_id = null"
                                            :options="utils.status_id"
                                            placeholder="Рейтинг"
                                            label="name">
                                            <template v-slot:no-options="{ search, searching }">
                                              <template v-if="searching">
                                                 Ничего не найдено по запросу <em>{{ search }}</em>.
                                              </template>
                                            </template>
                                        </vue-select>
                                        <label>Рейтинг</label>
                                        <div class="invalid-feedback">{{errors.user.status_id}}</div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating v-select-medium merchant_select" :class="{'v-select-error': errors.user.permissions}">
                                        <vue-select
                                            multiple
                                            v-model="user.permissions"
                                            @input="errors.user.permissions = null"
                                            :options="utils.permissions"
                                            placeholder="Уровень доступа"
                                            label="name">
                                            <template v-slot:no-options="{ search, searching }">
                                              <template v-if="searching">
                                                 Ничего не найдено по запросу <em>{{ search }}</em>.
                                              </template>
                                            </template>
                                        </vue-select>
                                        <label>Уровень доступа</label>
                                        <div class="invalid-feedback">{{errors.user.permissions}}</div>
                                    </div>
                                </div>


                                <h4 class="fs-1 mt-7">Верификация</h4>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating v-select-medium merchant_select" :class="{'v-select-error': errors.user.confirmations.email}">
                                        <vue-select
                                            v-model="user.confirmations.email"
                                            @input="errors.user.confirmations.email = null"
                                            :options="utils.confirmations.email"
                                            placeholder="Email"
                                            label="name">
                                            <template v-slot:no-options="{ search, searching }">
                                              <template v-if="searching">
                                                 Ничего не найдено по запросу <em>{{ search }}</em>.
                                              </template>
                                            </template>
                                        </vue-select>
                                        <label>Email</label>
                                        <div class="invalid-feedback">{{errors.user.confirmations.email}}</div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating v-select-medium merchant_select" :class="{'v-select-error': errors.user.confirmations.kyc}">
                                        <vue-select
                                            v-model="user.confirmations.kyc"
                                            @input="errors.user.confirmations.kyc = null"
                                            :options="utils.confirmations.kyc"
                                            placeholder="KYC"
                                            label="name">
                                            <template v-slot:no-options="{ search, searching }">
                                              <template v-if="searching">
                                                 Ничего не найдено по запросу <em>{{ search }}</em>.
                                              </template>
                                            </template>
                                        </vue-select>
                                        <label>KYC</label>
                                        <div class="invalid-feedback">{{errors.user.confirmations.kyc}}</div>
                                    </div>
                                </div>

                                <h4 class="fs-1 mt-7">Персональные данные</h4>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <input 
                                            v-model="user.personal.first_name"
                                            @input="errors.user.personal.first_name = null"
                                            :class="{'is-invalid': errors.user.personal.first_name}"
                                            class="form-control" 
                                            id="first_name" 
                                            type="text" 
                                            placeholder="Имя"/>
                                        <label for="first_name">Имя</label>
                                        <div class="invalid-feedback">{{errors.user.personal.first_name}}</div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <input 
                                            v-model="user.personal.last_name"
                                            @input="errors.user.personal.last_name = null"
                                            :class="{'is-invalid': errors.user.personal.last_name}"
                                            class="form-control" 
                                            id="last_name" 
                                            type="text" 
                                            placeholder="Фамилия"/>
                                        <label for="last_name">Фамилия</label>
                                        <div class="invalid-feedback">{{errors.user.personal.last_name}}</div>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <input 
                                            v-model="user.personal.surname"
                                            @input="errors.user.personal.surname = null"
                                            :class="{'is-invalid': errors.user.personal.surname}"
                                            class="form-control" 
                                            id="surname" 
                                            type="text" 
                                            placeholder="Отчество"/>
                                        <label for="surname">Отчество</label>
                                        <div class="invalid-feedback">{{errors.user.personal.surname}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <date-range-picker
                                            :class="{'is-invalid': errors.user.personal.birthday}"
                                            @update="errors.user.personal.birthday = null"
                                            :locale-data="datepiker"
                                            :opens="pikerparams.opens"
                                            :close-on-esc="pikerparams.closeonesc"
                                            :auto-apply="pikerparams.autoapply"
                                            :single-date-picker="pikerparams.single"
                                            :maxDate="pikerparams.maxdate"
                                            :ranges="pikerparams.ranges"
                                            :showDropdowns="pikerparams.dropdowns"
                                            v-model="user.personal.birthday">
                                        </date-range-picker>

                                        <label>Дата рождения</label>
                                        <div class="invalid-feedback">{{errors.user.personal.birthday}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating v-select-medium merchant_select" :class="{'v-select-error': errors.user.personal.sex}">
                                        <vue-select
                                            v-model="user.personal.sex"
                                            @input="errors.user.personal.sex = null"
                                            :options="utils.sex"
                                            placeholder="Пол"
                                            label="name">
                                            <template v-slot:no-options="{ search, searching }">
                                              <template v-if="searching">
                                                 Ничего не найдено по запросу <em>{{ search }}</em>.
                                              </template>
                                            </template>
                                        </vue-select>
                                        <label>Пол</label>
                                        <div class="invalid-feedback">{{errors.user.personal.sex}}</div>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-6">
                                    <div class="form-floating">
                                        <input 
                                            v-model="user.personal.phone.international"
                                            @input="errors.user.personal.phone = null"
                                            :class="{'is-invalid': errors.user.personal.phone}"
                                            class="form-control" 
                                            id="phone" 
                                            type="text" 
                                            placeholder="Телефон"/>
                                        <label for="phone">Телефон</label>
                                        <div class="invalid-feedback">{{errors.user.personal.phone}}</div>
                                    </div>
                                </div>

                                <h4 class="fs-1 mt-7">Отпечаток при регистрации</h4>

                                <div class="col-sm-6 col-md-4">
                                    <div class="form-floating">
                                        <input 
                                            v-model="user.geo.ip"
                                            class="form-control" 
                                            id="geo_ip" 
                                            type="text" 
                                            placeholder="IP адрес"
                                            disabled/>
                                        <label for="geo_ip">IP адрес</label>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-4">
                                    <div class="form-floating">
                                        <input 
                                            v-model="user.geo.country"
                                            class="form-control" 
                                            id="geo_country" 
                                            type="text" 
                                            placeholder="Страна"
                                            disabled/>
                                        <label for="geo_country">Страна</label>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-4">
                                    <div class="form-floating">
                                        <input 
                                            v-model="user.geo.city"
                                            class="form-control" 
                                            id="geo_city" 
                                            type="text" 
                                            placeholder="Город"
                                            disabled/>
                                        <label for="geo_city">Город</label>
                                    </div>
                                </div>

                                <div class="col-sm-6 col-md-4">
                                    <div class="form-floating">
                                        <input 
                                            v-model="user.agent.device"
                                            class="form-control" 
                                            id="agent_device" 
                                            type="text" 
                                            placeholder="Девайс"
                                            disabled/>
                                        <label for="agent_device">Девайс</label>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-4">
                                    <div class="form-floating">
                                        <input 
                                            v-model="user.agent.browser.name"
                                            class="form-control" 
                                            id="agent_browser" 
                                            type="text" 
                                            placeholder="Браузер"
                                            disabled/>
                                        <label for="agent_browser">Браузер</label>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-md-4">
                                    <div class="form-floating">
                                        <input 
                                            v-model="user.agent.platform.name"
                                            class="form-control" 
                                            id="agent_platform" 
                                            type="text" 
                                            placeholder="Платформа"
                                            disabled/>
                                        <label for="agent_platform">Платформа</label>
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div class="col-xl-4">
                            <h4 class="fs-1 mb-4">Активность</h4>

                            <div class="form-check mb-2">
                                <label class="form-check-label fw-normal">
                                    Дата регистрации: {{ $moment(user.created_at).format('YYYY-MM-DD H:mm:ss') }}
                                </label>
                            </div>
                            <div class="form-check mb-2">
                                <label class="form-check-label fw-normal">
                                    Последнее обновление: {{ $moment(user.updated_at).format('YYYY-MM-DD H:mm:ss') }}
                                </label>
                            </div>
                            <div class="form-check mb-2">
                                <label class="form-check-label fw-normal">
                                    Последний онлайн: {{ $moment(user.last_activity).format('YYYY-MM-DD H:mm:ss') }}
                                </label>
                            </div>
                            <div class="form-check mb-2">
                                <label class="form-check-label fw-normal">
                                    Последний Cashback: {{ $moment(user.cashback_claimed_at).format('YYYY-MM-DD H:mm:ss') }}
                                </label>
                            </div>
                            <div class="form-check mb-4" v-if="$moment(user.restraint_before).format('YYYY-MM-DD H:mm:ss') >= $moment().format('YYYY-MM-DD H:mm:ss')">
                                <label class="form-check-label fw-normal">
                                    Самоограничение до: {{ $moment(user.restraint_before).format('YYYY-MM-DD H:mm:ss') }}
                                </label>
                            </div>

                            <h4 class="fs-1 mb-4">Сменить пароль</h4>

                            <form class="mb-9" v-on:submit.prevent="changePassword">
                                <div class="row g-3 mb-4">
                                    <div class="col-sm-6 col-md-12">
                                        <div class="form-floating">
                                            <input
                                                v-model="password.password"
                                                @input="errors.password.password = null"
                                                :class="{'is-invalid': errors.password.password}"
                                                class="form-control" 
                                                id="password" 
                                                type="password" 
                                                placeholder="Новый пароль"/>
                                            <label for="password">Новый пароль</label>
                                            <div class="invalid-feedback">{{errors.password.password}}</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-12">
                                        <div class="form-floating">
                                            <input 
                                                v-model="password.password_confirmation"
                                                @input="errors.password.password_confirmation = null"
                                                :class="{'is-invalid': errors.password.password_confirmation}"
                                                class="form-control" 
                                                id="password_confirmation" 
                                                type="password" 
                                                placeholder="Подтвердите новый пароль"/>
                                            <label for="password_confirmation">Подтвердите новый пароль</label>
                                            <div class="invalid-feedback">{{errors.password.password_confirmation}}</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-12">
                                        <div class="d-flex">
                                            <button
                                                :class="{'disabled': btn_preloader}"
                                                class="btn btn-primary px-5 w-100 text-nowrap" 
                                                type="submit">
                                                <span class="spinner-border spinner-border-sm spinner-border-custom" role="status" v-if="btn_preloader"></span>
                                                <span>Сменить пароль</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>

                    </div>
                </form>
                
                <footer-main/>

            </div>

        </div>
    </main>
</template>

<script>
export default {
    props: ['id'],
    data () {
        return {
            preloader: true,
            btn_preloader: false,
            user: null,
            password: {
                password: null,
                password_confirmation: null
            },
            utils: {
                merchants: [],
                status_id: [],
                permissions: [],
                countries: [],
                statuses: [
                    {
                        name: 'Активный',
                        key: 1
                    },
                    {
                        name: 'Заблокирован',
                        key: 0
                    },
                    {
                        name: 'Самоограничен',
                        key: 2
                    },
                ],
                confirmations: {
                    email: [
                        {
                            name: 'Верифицирован',
                            key: true
                        },
                        {
                            name: 'Не верифицирован',
                            key: false
                        }
                    ],
                    kyc: [
                        {
                            name: 'Верифицирован',
                            key: true
                        },
                        {
                            name: 'Не верифицирован',
                            key: false
                        }
                    ]
                },
                sex: [
                    {
                        name: 'Мужской',
                        key: 1
                    },
                    {
                        name: 'Женский',
                        key: 2
                    }
                ]
            },
            errors: {
                user: {
                    email: false,
                    username: false,
                    personal: {
                        phone: false,
                        sex: false,
                        birthday: false,
                        last_name: false,
                        surname: false,
                        first_name: false
                    },
                    balance: false,
                    balance_bonus: false,
                    merchant_id: false,
                    status_id: false,
                    confirmations: {
                        email: false,
                        kyc: false
                    },
                    permissions: false,
                    status: false
                },
                password: {
                    password: false,
                    password_confirmation: false
                }
            },
            datepiker: {
                direction: 'ltr',
                format: 'yyyy.mm.dd',
                separator: ' - ',
                applyLabel: 'Применить',
                cancelLabel: 'Отмена',
                weekLabel: 'W',
                customRangeLabel: 'Настраиваемый диапазон',
                daysOfWeek: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
                monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
                firstDay: 0
            },
            pikerparams: {
                showcalendars: false,
                closeonesc: true,
                autoapply: true,
                single: true,
                ranges: false,
                dropdowns: true,
                maxdate: this.$moment().set({year: this.$moment().get('year') - 18}).format('Y-MM-DD'),
                opens: 'right'
            }
        }
    },
    methods: {
        async getItem () {
            await this.$axios.post('/users/item/' + this.$props.id)
            .then((response) => {
                this.preloader = false
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':
                        this.user = response.data

                        this.user.status = this.utils.statuses.find(row => row.key == this.user.status)

                        this.user.confirmations.email = this.utils.confirmations.email.find(row => row.key == this.user.confirmations.email)
                        this.user.confirmations.kyc = this.utils.confirmations.kyc.find(row => row.key == this.user.confirmations.kyc)

                        this.user.personal.birthday = {
                            startDate: this.user.personal.birthday ? this.$moment(this.user.personal.birthday).format('Y-MM-DD') : null
                        }

                        this.user.personal.sex = this.utils.sex.find(row => row.key == this.user.personal.sex)

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })     
        },
        updateItem () {
            this.btn_preloader = true

            var permissions = []

            for (let permission of this.user.permissions) {
                permissions.push(permission.slug)
            }

            this.$axios.post('/users/update', JSON.stringify({
                id: this.$props.id,
                email: this.user.email,
                username: this.user.username,
                personal: {
                    phone: this.user.personal.phone,
                    sex: this.user.personal.sex ? this.user.personal.sex.key: null,
                    birthday: this.user.personal.birthday.startDate ? this.$moment(this.user.personal.birthday.startDate).format('Y-MM-DD') : null,
                    first_name: this.user.personal.first_name,
                    last_name: this.user.personal.last_name,
                    surname: this.user.personal.surname
                },
                balance: this.user.balance,
                balance_bonus: this.user.balance_bonus,
                merchant_id: this.user.merchant_id.id,
                status_id: this.user.status_id.id,
                confirmations: {
                    email: this.user.confirmations.email.key,
                    kyc: this.user.confirmations.kyc.key
                },
                permissions: permissions,
                status: this.user.status.key
            }))
            .then((response) => {
                this.btn_preloader = false
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':
                        this.$toast.success(response.message)

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                this.btn_preloader = false
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errorByPatch(this.errors.user, response.field, response.message)
                    } else {
                        this.$toast.error(response.message)
                    }
                }
            })
        },
        changePassword () {
            if(!this.password.password) {
                this.errors.password.password = 'Это поле обязательно'
                return
            }
            if(!this.password.password_confirmation) {
                this.errors.password.password_confirmation = 'Это поле обязательно'
                return
            }

            this.btn_preloader = true
            this.$axios.post('/users/changepassword/' + this.$props.id, JSON.stringify(this.password))
            .then((response) => {
                this.btn_preloader = false
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':
                        this.password = {
                            password: null,
                            password_confirmation: null
                        }
                        this.$toast.success(response.message)

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                this.btn_preloader = false
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    if('field' in response) {
                        this.errors.password[response.field] = response.message
                    } else {
                        this.$toast.error(response.message)
                    }
                }
            })
        },
        errorByPatch (layer, path, value) {
            path = path.split('.')

            path.forEach((value, index) => {
                if (!isNaN(value)) {
                    path[index] = parseInt(value)
                }
            })

            for (var i = 0; i < path.length; i++) {
                if (value != null && i + 1 === path.length) {
                    layer[path[i]] = value
                }
                layer = layer[path[i]]
            }
            return layer
        },
        getMerchants () {
            this.$axios.post('/merchants/items')
            .then((response) => {
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':
                        for (let item of response.data) {
                            this.utils.merchants.push({
                                name: item.name,
                                id: item.id
                            })

                            if(this.user.merchant_id == item.id) {
                                this.user.merchant_id = {
                                    name: item.name,
                                    id: item.id
                                }
                            }
                        }

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })     
        },
        getStatuses () {
            this.$axios.post('/users/status/items')
            .then((response) => {
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':
                        for (let item of response.data) {
                            this.utils.status_id.push({
                                name: item.name,
                                id: item.id
                            })

                            if(this.user.status_id == item.id) {
                                this.user.status_id = {
                                    name: item.name,
                                    id: item.id
                                }
                            }
                        }

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)

                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })     
        },
        getPermissions () {
            this.$axios.post('/users/permissions/items')
            .then((response) => {
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':
                        for (let item of response.data) {
                            this.utils.permissions.push({
                                name: item.name,
                                slug: item.slug
                            })
                        }

                        var permissions = []

                        for (var permission of this.user.permissions) {
                            let item = this.utils.permissions.find(row => row.slug == permission)
                            permissions.push(item)
                        }

                        this.user.permissions = permissions

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                let response = error.response.data
                this.setUser(response.user)
                
                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })     
        },
        async getCountries () {
            await this.$axios.post('/utils/countries/items')
            .then((response) => {
                response = response.data
                switch (response.status) {
                    case 'error':
                        this.$toast.error(response.message)
                        break
                    case 'success':

                        for(let item in response.data) {
                            this.utils.countries.push({
                                name: response.data[item],
                                key: item
                            })
                        }

                        this.setUser(response.user)
                        break
                    default:
                        this.$toast.error('Unknown error')
                }
            }).catch((error) => {
                console.log(error)
                let response = error.response.data
                this.setUser(response.user)
                
                if('status' in response && response.status == 'error') {
                    this.$toast.error(response.message)
                }
            })     
        },
    },
    async beforeMount() {
        await this.getCountries()
        await this.getItem()
        this.getMerchants()
        this.getStatuses()
        this.getPermissions()
    }
}
</script>

<style lang="css" scoped>
    ::v-deep .vs__clear {
        display: none;
    }
</style>
