export default {
  ApplicationNAME: 'Admin PureGold',
  ApplicationDomainAPI: 'https://api-pure-gold.life',
  ApplicationAPI: 'https://api-pure-gold.life/api/v1/admin',
  ApplicationGamesAPI: 'https://slotegator.api-pure-gold.life/api/v1',
  ApplicationStaticURL: 'https://static-pure-gold.life',
  ApplicationURL: 'https://pure-gold.casino',
  Langs: ['ru', 'en'],
  RecaptchaV3: {
    token: '',
    action: 'casino'
  },
  RecaptchaV2: {
    token: ''
  },
  Timezone: 'Europe/Moscow'
}
